import React from "react";
import { Route, Redirect } from "react-router-dom";


// import TopMenuHeader from "./Header";
import MustRequestSelectedRoute from "./MustRequestSelectedRoute";

export const AppLayout = ({
  children,
  // activeMenu
}) => children;

export const PublicRoute = ({ component: Component, activeMenu, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Component {...props} />
        </>
      )}
    />
  );
};

export const PrivateRoute = ({ component: Component, activeMenu, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <AppLayout activeMenu={activeMenu}>
            <Component {...props} />
          </AppLayout>
        ) : (
          <Redirect to="login" />
        )
      }
    />
  );
};

export const MustRequestSelectedPublicRoute = ({
  component: Component,
  activeMenu,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AppLayout activeMenu={activeMenu}>
          <MustRequestSelectedRoute>
            <Component {...props} />
          </MustRequestSelectedRoute>
        </AppLayout>
      )}
    />
  );
};

export const MustRequestSelectedPrivateRoute = ({
  component: Component,
  activeMenu,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <AppLayout activeMenu={activeMenu}>
            <MustRequestSelectedRoute>
              <Component {...props} />
            </MustRequestSelectedRoute>
          </AppLayout>
        ) : (
          <Redirect to="login" />
        )
      }
    />
  );
};
