/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Tag, Typography, Row, Col, List, Space, Avatar } from "antd";
// import { formatCurrency } from "../../servos/numberHelpers";
import ProfessionSelectList from "./ProfessionalSelectList";

import { isDesktop, isMobile } from "react-device-detect";
import Spacer from "../../components/Spacer";

// const responsiveGutter = { xs: 8, sm: 16, md: 16, lg: 16 };

function RenderReasonList({
  reasons,
  availabilityRequests,
  toggleItem,
  practitionerId,
  multiSelect,
}) {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <List
        itemLayout="vertical"
        dataSource={reasons}
        renderItem={(item, index) => (
          <List.Item key={index} onClick={() => setVisible(!visible)}>
            <>
              <Row justify="space-between" gutter={[32, 8]} align="middle">
                <Col md={18} xs={24}>
                  <Space>
                    {item.coverPhoto.url && (
                      <>
                        <Avatar
                          size={32}
                          shape="square"
                          src={item.coverPhoto.url}
                        />
                      </>
                    )}
                    <Space>
                      <Space direction="vertical" size="0">
                        <Space>
                          <Typography.Text
                            strong
                            style={{ margin: 0, padding: 0 }}
                          >
                            {item.name}
                          </Typography.Text>
                        </Space>
                      </Space>
                      {isDesktop && (
                        <>
                          {item.minutes > 0 && (
                            <Tag color="#eee">
                              <span style={{ color: "#00000075" }}>
                                {item.minutes} mins
                              </span>
                            </Tag>
                          )}

                          {item.price > 0 && (
                            <Tag color="#eee">
                              <span style={{ color: "#00000075" }}>
                                ${item.price}
                              </span>
                            </Tag>
                          )}
                        </>
                      )}
                    </Space>
                  </Space>
                  {item.description && (
                    <div style={{ maxHeight: "350px", overflow: "auto" }}>
                      <Typography.Text
                        type="secondary"
                        style={{ padding: 0, margin: 0 }}
                      >
                        {item.description}
                      </Typography.Text>
                    </div>
                  )}
                </Col>
                {isDesktop && (
                  <Col flex="200px">
                    {!multiSelect && (
                      <ProfessionSelectList
                        toggleItem={toggleItem}
                        practitioners={item.displayPractitioners}
                        appointmentReasonId={item.id}
                        availabilityRequests={availabilityRequests}
                      />
                    )}
                  </Col>
                )}

                {isMobile && (
                  <>
                    <Col span={24}>
                      {item.minutes > 0 && (
                        <Tag color="#eee">
                          <span style={{ color: "#00000075" }}>
                            {item.minutes} mins
                          </span>
                        </Tag>
                      )}

                      {item.price > 0 && (
                        <Tag color="#eee">
                          <span style={{ color: "#00000075" }}>
                            ${item.price}
                          </span>
                        </Tag>
                      )}
                    </Col>
                    <Spacer />
                  </>
                )}

                {isMobile && (
                  <Col xs={24}>
                    {!multiSelect && (
                      <ProfessionSelectList
                        toggleItem={toggleItem}
                        practitioners={item.displayPractitioners}
                        appointmentReasonId={item.id}
                        availabilityRequests={availabilityRequests}
                      />
                    )}
                  </Col>
                )}
              </Row>
            </>
          </List.Item>
        )}
      />
    </>
  );
}
export default RenderReasonList;
