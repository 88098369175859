import axios from "axios";
import humps from "humps";
import { normalize } from "normalizr";

const crypto = require("crypto");

export const generateRequestHeaders = () => {
	const httpDate = new Date().toUTCString();

	const hmac = crypto.createHmac(
		"sha256",
		process.env.REACT_APP_ONLINE_BOOKINGS_SECRET,
	);
	const signature = hmac.update(httpDate);

	return {
		headers: {
			"X-Date": httpDate,
			"X-Signature": Buffer.from(signature.digest("hex")).toString("base64"),
		},
	};
};

export const convertRequest = (data) => humps.decamelizeKeys(data);
export const convertResponse = (data) => humps.camelizeKeys(data);

// Normalize a certain set of data
export const normalizeData = (data, schema) =>
	normalize(convertResponse(data), schema);
// Generally normalize a response
export const normalizeResponse = (response, schema) => ({
	response: normalizeData(response.data, schema),
});

export const normalizeDependatntResponse = (response, schema) => ({
	response: normalizeData(response.data.data, schema),
});

export const normalizeResponseWithPage = (response, schema) => ({
	response: {
		...normalizeData(response.data.data, schema),
		page: { ...convertResponse(response.data.page) },
	},
});

// Some default response functions
export const handleResponse = (response) => ({
	response: convertResponse(response.data),
});
export const errorResponse = (error) => ({
	errors: convertResponse(error.response.data.errors),
});

export const makeDELETERequest = (uri, config = {}) =>
	axios.delete(uri, { ...config, ...generateRequestHeaders() });

export const makeGETRequest = (uri, config = {}) =>
	axios.get(uri, { ...config, ...generateRequestHeaders() });

export const makePATCHRequest = (uri, config = {}) => (body) =>
	axios.patch(uri, convertRequest(body), {
		...config,
		...generateRequestHeaders(),
	});

export const makePOSTRequest = (uri, config = {}) => (body) =>
	axios.post(uri, convertRequest(body), {
		...config,
		...generateRequestHeaders(),
	});
