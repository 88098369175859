import React from "react";
import { connect } from "react-redux";
import * as actions from "modules/Session/actions";
import { getOtpMethod } from "../reducers";
import NewPatientForm from "./NewPatientForm";

function NewPatient({ account, practiceId, addPatientRequest, otpMethod }) {
  return (
    <NewPatientForm
      onSubmit={(values) =>
        addPatientRequest(account, practiceId, values, otpMethod)
      }
    />
  );
}

const mapStateToProps = (state) => ({ otpMethod: getOtpMethod(state.session) });

export default connect(mapStateToProps, actions)(NewPatient);
