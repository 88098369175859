import React from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { Availability } from "./modules/Calendar";
import { AppointmentReasons } from "./modules/Service";
import { Location } from "./modules/Location";
import * as Sentry from "@sentry/react";
import { Login, Session, Guest } from "./modules/Session";
import { Confirm, Complete } from "./modules/Booking";
import createStore, { history } from "./createStore";
import RootSaga from "./sagas";
import { initIterceptor } from "./interceptor";
import {
  PublicRoute,
  MustRequestSelectedPublicRoute,
  // MustRequestSelectedPrivateRoute,
} from "./Layout";

import "antd/dist/antd.css";
import "./App.less";
import "./Global.less";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

const store = createStore({}, sentryReduxEnhancer);
store.runSaga(RootSaga);
initIterceptor();

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <PublicRoute exact path="/booking/:account" component={Location} />
        <PublicRoute
          exact
          path="/booking/:account/locations/:locationId/reasons"
          component={AppointmentReasons}
        />
        <MustRequestSelectedPublicRoute
          exact
          path="/booking/:account/locations/:locationId/date"
          component={Availability}
        />
        <PublicRoute
          exact
          path="/booking/:account/locations/:locationId/reasons/:reasonId/date"
          component={Availability}
        />
        <PublicRoute
          exact
          path="/booking/:account/locations/:locationId/practitioner/:practitionerId/date"
          component={Availability}
        />
        <PublicRoute
          exact
          path="/booking/:account/locations/:locationId/reasons/:reasonId/practitioner/:practitionerId/date"
          component={Availability}
        />
        <MustRequestSelectedPublicRoute
          exact
          path="/booking/:account/locations/:locationId/login"
          component={Login}
        />
        <MustRequestSelectedPublicRoute
          exact
          path="/booking/:account/locations/:locationId/guest"
          component={Guest}
        />
        <MustRequestSelectedPublicRoute
          exact
          path="/booking/:account/locations/:locationId/session"
          component={Session}
        />
        <PublicRoute
          exact
          path="/booking/:account/locations/:locationId/confirm"
          component={Confirm}
        />
        <MustRequestSelectedPublicRoute
          exact
          path="/booking/:account/locations/:locationId/complete"
          component={Complete}
        />
      </Router>
    </Provider>
  );
}

export default App;
