export const LOGIN_REQUEST = "user/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "user/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "user/LOGIN_FAILURE";

export const SIGNUP_REQUEST = "user/SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "user/SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "user/SIGNUP_FAILURE";

export const RESET_PASSWORD_REQUEST = "user/RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "user/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "user/RESET_PASSWORD_FAILURE";

export const ADD_GUEST_REQUEST = "user/ADD_GUEST_REQUEST";
export const ADD_GUEST_SUCCESS = "user/ADD_GUEST_SUCCESS";
export const ADD_GUEST_FAILURE = "user/ADD_GUEST_FAILURE";

// export const GENERATE_OTP_RESUBMIT_REQUEST =
//   "user/GENERATE_OTP_RESUBMIT_REQUEST";
export const GENERATE_OTP_REQUEST = "user/GENERATE_OTP_REQUEST";
export const GENERATE_OTP_SUCCESS = "user/GENERATE_OTP_SUCCESS";
export const GENERATE_OTP_FAILURE = "user/GENERATE_OTP_FAILURE";
export const CLEAR_OTP_KEY = "user/CLEAR_OTP_KEY";

export const VERIFY_OTP_REQUEST = "user/VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "user/VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "user/VERIFY_OTP_FAILURE";

export const FETCH_PATIENTS_REQUEST = "user/FETCH_PATIENTS_REQUEST";
export const FETCH_PATIENTS_SUCCESS = "user/FETCH_PATIENTS_SUCCESS";
export const FETCH_PATIENTS_FAILURE = "user/FETCH_PATIENTS_FAILURE";

export const ADD_PATIENT_REQUEST = "user/ADD_PATIENT_REQUEST";
export const ADD_PATIENT_SUCCESS = "user/ADD_PATIENT_SUCCESS";
export const ADD_PATIENT_FAILURE = "user/ADD_PATIENT_FAILURE";

export const CLEAR_PATIENTS = "user/CLEAR_PATIENTS";
