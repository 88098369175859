import React from "react";

function Spacer(props) {
  const { large, margin } = props;

  return (
    <div
      style={{
        marginTop: large ? 48 : 8,
        marginBottom: margin,
        width: "100%",
        height: margin,
      }}
    />
  );
}

export default Spacer;
