import axios from 'axios';

export const initIterceptor = () => {
	//if (localStorage.getItem('auth')) {
	axios.interceptors.request.use(function(config) {
		const token = localStorage.getItem('token');
		config.headers.Authorization = token;

		return config;
	});
	//}
};
