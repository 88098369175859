/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// import ProgressBar from "components/ProgressBar";
import Reasons from "./Reasons";
// import PracticeProfessionalServices from "./PracticeProfessionalServices";
import { accountBaseUrl } from "../../helpers/account";
import * as actions from "./actions";
import { getAppointmentReasons } from "./reducers";
import { getAvailabilityRequests } from "../Booking/reducers";
import { clearBooking } from "modules/Booking/actions";
// import { isMobile } from "react-device-detect";
// import { ShoppingCartOutlined } from "@ant-design/icons";
import { ButtonSize } from "../../GlobalConfig";
// import ProgressBar from "../../components/ProgressBar";
import ContentContainer from "../../components/ContentContainer";
import uniqBy from "lodash/uniqBy";
import { ArrowLeftOutlined } from "@ant-design/icons";

// const { Title } = Typography;

const SelectReason = ({
	// fetchCatalogueItemsRequest,
	match,
	availabilityRequests,
	clearBooking,
}) => {
	useEffect(() => {
		clearBooking();
	}, []);

	const { params } = match;
	const practiceId = params.locationId;

	const uniqueAvailabilityRequests = uniqBy(availabilityRequests, "reasonId");

	return (
		<ContentContainer
			pageTitle="What would you like to book?"
			step="2"
			showNext={false}
			stepPercentage={40}
			backButton={
				<Link to={`${accountBaseUrl}`}>
					<Button shape="circle" type="text" icon={<ArrowLeftOutlined />} />
				</Link>
			}
			extra={
				availabilityRequests &&
				uniqueAvailabilityRequests.length > 0 && (
					<Link
						className="ml-lg"
						to={`${accountBaseUrl}/locations/${practiceId}/date`}
					>
						<Button size={ButtonSize} type="primary" className="next-button">
							Next
						</Button>
					</Link>
				)
			}
		>
			<Reasons practiceId={params.locationId} />
		</ContentContainer>
	);
};

const mapStateToProps = (state) => ({
	reasons: getAppointmentReasons(state.appointmentReasons),
	availabilityRequests: getAvailabilityRequests(state.booking),
});

export default connect(mapStateToProps, { ...actions, clearBooking })(
	SelectReason,
);
