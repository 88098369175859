/* eslint-disable import/no-anonymous-default-export */
import { normalizeResponse, errorResponse, makeGETRequest } from "utils/apiRequests";
import { practitionersListSchema } from "./schema";

import { apiUrl } from "../../config";

const fetchPractitioners = () => {
  const url = apiUrl()("pracitioners");
  const handleResponse = (response) => normalizeResponse(response, practitionersListSchema);
  return makeGETRequest(url).then(handleResponse).catch(errorResponse);
};

export default {
  fetchPractitioners,
};
