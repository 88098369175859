import React from "react";
import Slot from "./Slot";
import { Row, Tag } from "antd";

function RenderSlot({
	label,
	availability,
	updateItem,
	practitioner,
	reason,
	matchingRequest,
	practiceId,
	reservationRequest,
	deleteReservationRequest,
	date,
}) {
	return (
		<>
			{availability && availability.length <= 0 && (
				<Tag color="#ddd">{label}: No slots available</Tag>
			)}

			{availability && availability.length > 0 && (
				<Row>
					{availability?.map((startTime, index) => (
						<Slot
							date={date}
							startTime={startTime}
							updateItem={updateItem}
							practitioner={practitioner}
							reason={reason}
							doesMatching={
								matchingRequest && matchingRequest.startTime === startTime
							}
							practiceId={practiceId}
							reservationKey={matchingRequest?.reservationKey}
							anyBatch={matchingRequest?.anyBatch}
							reservationRequest={reservationRequest}
							deleteReservationRequest={deleteReservationRequest}
						/>
					))}
				</Row>
			)}
		</>
	);
}

export default RenderSlot;
