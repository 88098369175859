import React from "react";
import { Switch } from "antd";

const SwitchField = (props) => {
  const { value } = props;

  return (
    <Switch
      disabled={true}
      checked={value}
      onChange={(newVal) => {
        props.onChange(newVal);
      }}
    />
  );
};

export default SwitchField;
