import React from "react";
import { Form, Button, Row, Col, Checkbox, Alert } from "antd";
import { Link } from "react-router-dom";

import { Field, reduxForm } from "redux-form";
import { PasswordField } from "components/AntdReduxForm";
import { required } from "redux-form-validators";
import { connect } from "react-redux";
import * as actions from "./actions";

function ResetPasswordForm(props) {
  const { handleSubmit } = props;

  return (
    <div className="center login-container" style={{ padding: 32 }}>
      <div className="wrapper-logo-login">Logo</div>
      <Alert message="You accepted invitation. create new password to login" type="success" />
      <form onSubmit={handleSubmit}>
        <h3 style={{ textAlign: "center" }}>Create new password</h3>

        <Field
          size="large"
          name="password"
          validate={[required()]}
          placeholder="Password"
          type="password"
          component={PasswordField}
          required
        />

        <Field
          size="large"
          name="passwordConfirmation"
          validate={[required()]}
          placeholder="Confirm password"
          type="password"
          component={PasswordField}
          required
        />

        <Row>
          <Col span={12}>
            <Form.Item style={{ float: "right" }}>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Link to="/forget_password">Forgot password</Link>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button block size="large" type="primary" htmlType="submit">
            Log in
          </Button>
        </Form.Item>

        <Col />
      </form>
    </div>
  );
}

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "ResetPasswordForm",
    enableReinitialize: true,
  })(ResetPasswordForm)
);
