import * as constants from "./constants";

export const fetchCatalogueItemsRequest = (practiceId) => ({
  type: constants.FETCH_APPOINTMENT_REASONS_REQUEST,
  practiceId,
});

export const fetchCatalogueItemsSuccess = (response) => ({
  type: constants.FETCH_APPOINTMENT_REASONS_SUCCESS,
  response,
});

export const fetchCatalogueItemsFailure = (errors) => ({
  type: constants.FETCH_APPOINTMENT_REASONS_FAILURE,
  errors,
});

export const fetchPracticeProfessionalAppointmentReasonsRequest = (
  practiceId,
  reasonId = null,
  practitionerId = null
) => ({
  type: constants.FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_REQUEST,
  practiceId,
  reasonId,
  practitionerId,
});

export const fetchPracticeProfessionalAppointmentReasonsSuccess = (
  response
) => ({
  type: constants.FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_SUCCESS,
  response,
});

export const fetchPracticeProfessionalAppointmentReasonsFailure = (errors) => ({
  type: constants.FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_FAILURE,
  errors,
});
