/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Card, Typography, Row, Col, Space, Button } from "antd";
import { EnvironmentFilled, PhoneFilled } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import {
  darkGrey,
  defaultHorizontalGutter,
  defaultVerticalGutter,
} from "../GlobalConfig";
import Spacer from "./Spacer";
import startCase from "lodash/startCase";

const { Text, Title } = Typography;

const PracticeAddress = ({ fullAddress }) => (
  <>
    {fullAddress && (
      <Space align="start">
        <EnvironmentFilled className="mr-md" style={{ color: darkGrey }} />
        <div
          dangerouslySetInnerHTML={{
            __html: fullAddress.replace(/\n\n/, "<br>"),
          }}
        />
      </Space>
    )}
  </>
);
const LocationCard = ({
  // id,
  name,
  phone,
  logo,
  location,
  coverPhoto,
  // businessFeatures,
  // border,
  hover,
  hideBookButton,
}) => (
  <>
    <Card
      className="location-card"
      hoverable
      cover={
        coverPhoto ? (
          <img
            className="cover-container"
            style={{
              backgroundColor: "#001529",
              objectFit: "cover",
              backgroundPosition: "center",
              height: 150,
            }}
            src={coverPhoto}
          />
        ) : (
          <div
            className="cover-container"
            style={{
              backgroundColor: "#ddd",
              objectFit: "cover",
              backgroundPosition: "center",
              height: 150,
            }}
          />
        )
      }
    >
      <Row
        justify="space-between"
        gutter={[defaultVerticalGutter, defaultHorizontalGutter]}
      >
        <Col
          flex="200px"
          style={{
            textAlign: "end",
            position: "absolute",
            top: 18,
            borderRadius: 4,
            background: "white",
          }}
        >
          {logo.url && (
            <img
              src={logo.url}
              style={{
                maxWidth: 180,
                maxHeight: 180,
                padding: 12,
              }}
            />
          )}
        </Col>
        <Col style={{ marginTop: coverPhoto ? -12 : 12 }}>
          <Title className="business-name" level={isMobile ? 4 : 3}>
            {startCase(name)}
          </Title>
          <Row>{<PracticeAddress {...location} />}</Row>
          {phone && (
            <div className="mt-md">
              <Text className="phone-filled">
                <PhoneFilled style={{ color: darkGrey }} className="mr-md" />
                {phone}
              </Text>
            </div>
          )}
        </Col>
      </Row>
      <Spacer />
      {!hideBookButton && <Button block>Book Now</Button>}
    </Card>
  </>
);

export default LocationCard;
