import * as constants from './constants';

export const fetchPractitionersRequest = () => ({
	type: constants.FETCH_PRACTITIONERS_REQUEST
});

export const fetchPractitionersSuccess = () => ({
	type: constants.FETCH_PRACTITIONERS_SUCCESS
});

export const fetchPractitionersFailure = () => ({
	type: constants.FETCH_PRACTITIONERS_FAILURE
});
