import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

function renderRecaptchaField(field) {
  const {
    meta: { touched, error },
  } = field;
  return (
    <div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={field.input.onChange}
      />
      <div>
        <p style={{ color: "#ff4d4f" }} className="has-error">
          {touched ? error : ""}
        </p>
      </div>
    </div>
  );
}

export default renderRecaptchaField;
