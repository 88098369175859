/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Calendar, Spin, Select, Row, Col, Button } from "antd";
import moment from "moment";
import { orderBy, flatten, filter, isEmpty, isNull, find } from "lodash";
import { getAvailabilityRange } from "./reducers";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
const format = "YYYY-MM-DD";
const uiFormat = "DD/MM/YYYY";
// const status = ["success", "error", "warning"];

function DatePicker({
	changeFromDate,
	id,
	fromDate,
	// leadTimeToBookInDays,
	// howFarCanBookInDays,
	availabilityRange,
	practiceId,
	fetchAvailabilityBetweenRangeRequest,
	availabilityRangeLoadingState,
}) {
	const [earliestDate, setEarliestDate] = React.useState(null);
	const [selectedDate, setSelectedDate] = React.useState(null);

	useEffect(() => {
		fetchAvailabilityBetweenRangeRequest(
			practiceId,
			moment().clone().subtract(1, "days").format(uiFormat),
			moment().clone().add(6, "weeks").format(uiFormat),
		);
	}, []);

	useEffect(() => {
		if (isNull(earliestDate) && availabilityRange) {
			earliestAvailability();
		}
	}, [availabilityRange]);

	const handleOnchange = (date) => {
		// let fromDate;
		// if (date.isBefore(new Date(), "day")) {
		//   fromDate = moment();
		// } else {
		//   fromDate = date.clone().subtract(1, "days");
		// }
		// let endDate = date.clone().add(5, "weeks");

		fetchAvailabilityBetweenRangeRequest(
			practiceId,
			date.clone().startOf("month").subtract(1, "weeks").format(uiFormat),
			date.clone().endOf("month").add(1, "weeks").format(uiFormat),
		);
	};

	const disabledDate = (date) => {
		const availableDate = find(availabilityRange.flat(), {
			day: date.format(format),
			available: true,
		});
		return !availableDate || false;
	};

	function earliestAvailability() {
		if (isEmpty(availabilityRange)) {
			setSelectedDate(moment());
		} else {
			const availableDates = availabilityRange.map((range) =>
				filter(range, ["available", true]),
			);

			var date = moment(orderBy(flatten(availableDates), "day")[0]?.day);
			setEarliestDate(moment(date));
			setSelectedDate(moment(date));
			changeFromDate(practiceId, date.format(uiFormat));
		}
	}

	return (
		<>
			{availabilityRangeLoadingState && (
				<Row justify="center">
					<Spin />
				</Row>
			)}
			{id && (
				<Calendar
					// dateCellRender={placeDots}
					headerRender={({ value, type, onChange, onTypeChange }) => {
						const currentYear = new Date().getFullYear();
						const currentMonth = new Date().getMonth();

						const selectedYear = value.year();
						const selectedMonth = value.month();

						const start = 0;
						const end = 12;
						const monthOptions = [];

						let current = value.clone();
						const localeData = value.localeData();
						const months = [];
						for (let i = 0; i < 12; i++) {
							current = current.month(i);
							months.push(localeData.monthsShort(current));
						}

						for (let i = start; i < end; i++) {
							if (selectedYear === currentYear) {
								// Don't want to show the previous months if the current year is selected
								if (i >= currentMonth) {
									monthOptions.push(
										<Select.Option key={i} value={i} className="month-item">
											{months[i]}
										</Select.Option>,
									);
								}
							} else {
								monthOptions.push(
									<Select.Option key={i} value={i} className="month-item">
										{months[i]}
									</Select.Option>,
								);
							}
						}

						const yearOptions = [];
						for (let i = currentYear; i <= currentYear + 2; i += 1) {
							yearOptions.push(
								<Select.Option key={i} value={i} className="year-item">
									{i}
								</Select.Option>,
							);
						}
						return (
							<div style={{ padding: 4 }}>
								<Row gutter={8} justify="space-between">
									<Button
										type="text"
										size="small"
										disabled={
											value
												.clone()
												.month(selectedMonth - 1)
												.month() < moment().month()
										}
										onClick={() => {
											const now = value.clone().month(selectedMonth - 1);
											onChange(now);
										}}
										icon={<LeftOutlined />}
									/>
									<Col>
										<Select
											style={{ marginRight: 8 }}
											size="small"
											dropdownMatchSelectWidth={false}
											value={selectedMonth}
											onChange={(newMonth) => {
												const now = value.clone().month(newMonth);
												onChange(now);
											}}
										>
											{monthOptions}
										</Select>

										<Select
											size="small"
											dropdownMatchSelectWidth={false}
											className="my-year-select"
											value={selectedYear}
											onChange={(newYear) => {
												const now = value.clone().year(newYear);
												onChange(now);
											}}
										>
											{yearOptions}
										</Select>
									</Col>
									<Col xs={0}>{fromDate}</Col>
									<Button
										type="text"
										size="small"
										onClick={() => {
											const now = value.clone().month(selectedMonth + 1);
											onChange(now);
										}}
										icon={<RightOutlined />}
									/>
								</Row>
							</div>
						);
					}}
					disabledDate={disabledDate}
					value={selectedDate || moment()}
					mode="month"
					fullscreen={false}
					onSelect={(v, m) => {
						if (v < moment()) {
							handleOnchange(earliestDate);
							setSelectedDate(earliestDate);
							changeFromDate(id, earliestDate.format(uiFormat));
						} else {
							handleOnchange(v);
							setSelectedDate(v);
							changeFromDate(id, v.format(uiFormat));
						}
					}}
				/>
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	availabilityRange: getAvailabilityRange(state.availability),
});

export default connect(mapStateToProps, {})(DatePicker);
