import { all, call, select, put, takeLatest } from "redux-saga/effects";
import { message } from "antd";
import { stopSubmit } from "redux-form";
import { push } from "connected-react-router";
import * as actions from "./actions";
import * as types from "./constants";
import { getGuestUserFormValues } from "./reducers";
import api from "./api";

export function* loginSaga({ user }) {
	const { response, errors } = yield call(api.login, { user });
	if (response) {
		yield put(actions.loginSuccess(response));
		yield call(() => localStorage.setItem("token", response.token));
		yield call(() =>
			localStorage.setItem("user", JSON.stringify(response.user)),
		);
		yield put(push("session"));
	} else {
		yield put(stopSubmit("LoginForm", errors));
		yield put(actions.loginFailure(errors));
	}
}

export function* resetPasswordSaga({ user }) {
	const { response, errors } = yield call(api.resetPassword, { user });
	if (response) {
		yield put(actions.resetPasswordSuccess(response));
		message.info("Password has been successfully updated.");
		yield put(push("/"));
	} else {
		yield put(stopSubmit("ResetPasswordForm", errors));
		yield put(actions.resetPasswordFailure(errors));
	}
}

export function* signupSaga({ user }) {
	const { response, errors } = yield call(api.signup, { user });
	if (response) {
		yield put(actions.signupSuccess(response));
		yield call(() => localStorage.setItem("token", response.token));
		yield call(() =>
			localStorage.setItem("user", JSON.stringify(response.user)),
		);
		yield put(push("session"));
	} else {
		yield put(stopSubmit("SignupForm", errors));
		yield put(actions.signupFailure(errors));
	}
}

export function* generateOtpSaga({ user }) {
	const { response, errors } = yield call(api.generateOtp, { user });
	if (response) {
		message.info(
			`One time password has been sent. Please check your ${
				user?.otpMethod === "email" ? "email" : "mobile"
			}`,
		);
		yield put(actions.generateOtpSuccess(response));
	} else {
		if (errors) message.error(errors);
		yield put(stopSubmit("GuestForm", errors));
		yield put(actions.generateOtpFailure(errors));
	}
}

export function* generateOtpResubmitSaga({ user }) {
	const { response, errors } = yield call(api.generateResubmitOtp, { user });
	if (response) {
		message.info(
			`One time password has been sent. Please check your ${
				user?.otpMethod === "email" ? "email" : "mobile"
			}`,
		);
		message.info(
			`One time password has been sent. Please check your ${
				user?.otpMethod === "email" ? "email" : "mobile"
			}`,
		);
		yield put(actions.generateOtpSuccess(response));
	} else {
		if (errors) message.error(errors);
		yield put(stopSubmit("GuestForm", errors));
		yield put(actions.generateOtpFailure(errors));
	}
}

export function* verifyOtpSaga({ account, practiceId, user }) {
	const { response, errors } = yield call(api.verifyOtp, { user });
	if (response) {
		// Pass the user value and put it in reducer formValues for future use
		yield put(actions.verifyOtpSuccess(user));
		// OTP SUCCESS FETCH matching patients
		yield put(actions.fetchPatientsRequest(account, practiceId, user));
	} else {
		if (errors && errors.otp) message.error(errors.otp);
		yield put(stopSubmit("GuestForm", errors));
		yield put(actions.verifyOtpFailure(errors));
	}
}

export function* fetchPatientSaga({ account, practiceId }) {
	const user = yield select((state) => getGuestUserFormValues(state.session));
	const { response, errors } = yield call(api.fetchPatients, {
		account,
		practiceId,
		user,
	});
	if (response) {
		yield put(actions.fetchPatientsSuccess(response));
	} else {
		yield put(actions.fetchPatientsFailure(errors));
	}
}

export function* addPatientSaga({
	account,
	practiceId,
	formValues,
	otpMethod,
}) {
	const { email, mobilePhone, mobileRegionCode, mobileRegionShort } =
		yield select((state) => getGuestUserFormValues(state.session));
	// Collect the form values from the first form and merge

	const mergedFormValues = {
		email,
		mobilePhone,
		mobileRegionCode,
		mobileRegionShort,
		otpMethod,
		...formValues,
	};

	const { response, errors } = yield call(api.addPatient, {
		account,
		practiceId,
		user: mergedFormValues,
	});
	if (response) {
		yield put(actions.addPatientSuccess(response));
		yield put(push("confirm"));
	} else {
		yield put(actions.addPatientFailure(errors));
	}
}

export default function* root() {
	yield all([yield takeLatest(types.ADD_PATIENT_REQUEST, addPatientSaga)]);
	yield all([yield takeLatest(types.FETCH_PATIENTS_REQUEST, fetchPatientSaga)]);
	yield all([yield takeLatest(types.VERIFY_OTP_REQUEST, verifyOtpSaga)]);
	yield all([yield takeLatest(types.GENERATE_OTP_REQUEST, generateOtpSaga)]);
	yield all([yield takeLatest(types.LOGIN_REQUEST, loginSaga)]);
	yield all([
		yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPasswordSaga),
	]);
	yield all([yield takeLatest(types.SIGNUP_REQUEST, signupSaga)]);
	// yield all([ yield takeLatest(types.ADD_GUEST_REQUEST, addGuestSaga) ]);
}
