import {
	Affix,
	Button,
	Col,
	Progress,
	Row,
	Space,
	Tooltip,
	Typography,
} from "antd";
import React from "react";
import { isDesktop, isMobile } from "react-device-detect";

import { primaryDark } from "../GlobalConfig";

const totalSteps = 5;
const paddingBottom = 48;
const maxWidth = 1300;
const padding = 16;
const headerStyle = {
	borderBottom: "1px solid #eee",
	padding: padding,
	background: "#ffffff60",
	backdropFilter: "blur(10px) saturate(180%)",
	WebkitBackdropFilter: "blur(10px) saturate(180%)",
};

function ContentContainer(props) {
	const {
		children,
		pageTitle,
		extra,
		showNext = true,
		step,
		stepPercentage,
		backButton,
	} = props;

	const footer = (
		<div
			style={{
				textAlign: "center",
				background: "#fafafa",
				padding: "4px",
			}}
		>
			<Typography.Link type="secondary" href="https://www.zavy360.com">
				Powered by Zavy360 Practice Software
			</Typography.Link>
		</div>
	);
	return (
		<>
			{isMobile ? (
				<>
					<div
						style={{
							overflowY: "auto",
							overflowX: "hidden",
							position: "relative",
							maxWidth: maxWidth,
							marginLeft: "auto",
							marginRight: "auto",
							paddingBottom: paddingBottom,
							height: "auto",
						}}
					>
						{/* Page title each page required */}
						<Affix offsetTop={0}>
							<Row
								justify="space-between"
								style={headerStyle}
								className="zavy-header"
							>
								<Col>{backButton}</Col>

								{/* <PageHeader /> */}
								<Col>
									{showNext ? (
										<Button disabled type="primary">
											Next
										</Button>
									) : (
										extra
									)}
								</Col>
							</Row>
						</Affix>

						<div style={{ padding: padding }}>
							<Row style={{ marginTop: 8 }}>
								<Col span={24}>
									<Typography.Title
										level={3}
										style={{ marginTop: 0, marginBottom: 0 }}
									>
										{pageTitle}
									</Typography.Title>
									<Typography.Text style={{ marginRight: 16 }} type="secondary">
										Step {step} of {totalSteps}
									</Typography.Text>
									<Progress
										style={{ maxWidth: 100 }}
										percent={stepPercentage}
										showInfo={false}
										strokeColor={primaryDark}
									/>
								</Col>
							</Row>
							{/* Page content  */}
						</div>

						<div
							style={{
								padding: "0px 16px 16px 16px",
								maxHeight: isDesktop && "400px",
							}}
						>
							{children}
						</div>
					</div>
					{footer}
				</>
			) : (
				<>
					<div
						style={{
							overflowY: "hidden",
							overflowX: "hidden",
							position: "relative",
							maxWidth: maxWidth,
							marginLeft: "auto",
							marginRight: "auto",
							paddingBottom: paddingBottom,
							height: "auto",
						}}
					>
						{/* Page title each page required */}
						<Row
							justify="space-between"
							style={headerStyle}
							className="zavy-header"
							align="middle"
						>
							<Col>{backButton}</Col>
							<Col>
								<Space>
									<Typography.Title
										level={3}
										style={{ marginTop: 0, marginBottom: 0 }}
									>
										{pageTitle}
									</Typography.Title>
									<div style={{ width: "100px", marginLeft: 16 }}>
										<Tooltip
											title={
												<Typography.Text
													style={{ marginRight: 16, color: "#fff" }}
													type="secondary"
												>
													Step {step} of {totalSteps}
												</Typography.Text>
											}
										>
											<Progress
												style={{ maxWidth: 100 }}
												percent={stepPercentage}
												showInfo={false}
												strokeColor={primaryDark}
											/>
										</Tooltip>
									</div>
								</Space>
							</Col>

							{/* <PageHeader /> */}
							<Col>
								{showNext ? (
									<Button disabled type="primary">
										Next
									</Button>
								) : (
									extra
								)}
							</Col>
						</Row>

						<div
							style={{
								position: "relative",
								height: "100%",
								maxHeight: "600px",
								overflowY: "auto",
								overflowX: "hidden",
							}}
						>
							<div style={{ padding: padding }}>{children} </div>
						</div>
						{/* {footer} */}
					</div>
				</>
			)}
		</>
	);
}

export default ContentContainer;
