import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Alert, Typography, Drawer } from "antd";
import { message } from "antd";
import { formatCurrency } from "../../servos/numberHelpers";
import {
	makePaymentRequest,
	makePaymentFailure,
	startBookingProcess,
} from "./actions";
import { getPaymentIntent } from "./api";
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import "./CardSectionStyles.css";
import { isMobile } from "react-device-detect";
import { closePaymentDrawer } from "./actions";
import { titleSize } from "../../GlobalConfig";

const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: "#32325d",
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: "antialiased",
			fontSize: "16px",
			"::placeholder": {
				color: "#aab7c4",
			},
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a",
		},
	},
};

function CardForm({ practiceId, paymentTotal }) {
	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();

	const patient = useSelector((state) => state.booking.currentPatient);
	const submitting = useSelector((state) => state.booking.saveBookingState);
	const bookingDate = useSelector((state) => state.booking.fromDate);
	const patientNote = useSelector((state) => state.booking.patientNote);
	const currentPractice = useSelector(
		(state) => state.locations.currentLocation,
	);
	const availabilityRequests = useSelector(
		(state) => state.booking.availabilityRequests,
	);

	const visible = useSelector((state) => state.booking.paymentDrawerState);

	const handleSubmit = async (event) => {
		event.preventDefault();

		dispatch(startBookingProcess());

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		const {
			response,
			// error
		} = await getPaymentIntent({
			practiceId: practiceId,
			patientId: patient.id ? patient.id : patient,
		});

		if (response) {
			const result = await stripe.confirmCardSetup(response.clientSecret, {
				payment_method: {
					card: elements.getElement(CardNumberElement),
					billing_details: {
						name: document.getElementById("card-name").value,
					},
				},
			});

			if (result.error) {
				dispatch(makePaymentFailure());

				message.error(
					`Sorry, we couldn't process your payment. ${result?.error?.message}`,
				);
			} else {
				dispatch(
					makePaymentRequest({
						practiceId,
						patientId: patient.id ? patient.id : patient,
						card: result,
						availabilityRequests,
						bookingDate,
						patientNote,
						successUrl: currentPractice?.successUrl,
					}),
				);
				dispatch(closePaymentDrawer());
			}
		} else {
			dispatch(makePaymentFailure());
			message.error("Sorry, something went wrong. Please try again later.");
		}
	};

	return (
		<Drawer
			title="Payment"
			placement="right"
			width={isMobile ? "calc(100vw)" : "calc(30vw)"}
			open={visible}
			onClose={() => {
				dispatch(closePaymentDrawer());
			}}
			footer={
				<Button
					style={{ float: "right", marginBottom: "10px" }}
					type="primary"
					onClick={handleSubmit}
					disabled={!stripe || submitting}
					loading={submitting}
				>
					I Accept and Confirm
				</Button>
			}
		>
			<Typography.Title level={titleSize}>Enter Card Details</Typography.Title>
			<Alert
				description={
					<>
						<Typography.Text>
							Payment is required to book an appointment. Please enter your card
							details.
						</Typography.Text>
						<br />
						<br />
						<Typography.Text strong>
							Total: {formatCurrency(paymentTotal)}
						</Typography.Text>
					</>
				}
				message="Important Information"
			/>
			<br />
			<form>
				{/* <CardElement /> */}
				<div className="card-elements">
					<Row>
						<Col span={24}>
							<label className="card__input-wrapper card__input-wrapper--block">
								Name on Card
								<div className="card-name field">
									<input
										id="card-name"
										name="name"
										placeholder="Jenny Rosen"
										className="form-control"
										required
									/>
								</div>
							</label>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<label className="card__input-wrapper card__input-wrapper--block">
								Card Number
								<div className="card-number field">
									<CardNumberElement options={CARD_ELEMENT_OPTIONS} />
								</div>
							</label>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<label className="card__input-wrapper card__input-wrapper--block">
								Expiry
								<div className="card-expiry field">
									<CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
								</div>
							</label>
						</Col>
						<Col span={11} offset={1}>
							<label className="card__input-wrapper card__input-wrapper--block">
								CVC
								<div className="card-cvc field">
									<CardCvcElement options={CARD_ELEMENT_OPTIONS} />
								</div>
							</label>
						</Col>
					</Row>
				</div>
			</form>
		</Drawer>
	);
}

export default CardForm;
