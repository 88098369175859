/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { Layout, Row, Col } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { accountBaseUrl } from "../../helpers/account";
import * as actions from "./actions";
import LocationCard from "components/LocationCard";
import { getLocations } from "./reducers";
import { clearBooking } from "../Booking/actions";
import ContentContainer from "../../components/ContentContainer";

const { Content } = Layout;

const Locations = ({ fetchLocationsRequest, locations, clearBooking }) => {
	useEffect(() => {
		fetchLocationsRequest();
		clearBooking();
	}, []);
	return (
		<ContentContainer
			pageTitle="Select Location"
			step="1"
			stepPercentage={20}
			showNext={false}
		>
			<Content className="">
				<Row justify="space-between" gutter={[24, 24]}>
					{locations &&
						locations.map((location) => (
							<Col md={12} xs={24} key={location.id}>
								<Link to={`${accountBaseUrl}/locations/${location.id}/reasons`}>
									<LocationCard {...location} border={true} />
								</Link>
							</Col>
						))}
				</Row>
			</Content>
		</ContentContainer>
	);
};

const mapStateToProps = (state) => ({
	locations: getLocations(state.locations),
});

export default connect(mapStateToProps, { ...actions, clearBooking })(
	Locations,
);
