import { all, call, select, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import { message } from "antd";
import Raven from "raven-js";

import * as actions from "./actions";
import * as types from "./constants";
import api from "./api";
import _ from "lodash";
import {
	getAvailabilityRequests,
	getFromDate,
	getCurrentPatient,
	getCurrentLocation,
} from "./reducers";

export function* saveBookingSaga({ params, successUrl }) {
	Raven.config(
		"https://11e059c43e1877ccee505f6dfbb0f2ae@o4505599540068352.ingest.us.sentry.io/4506897717919744",
	).install();

	const availabilityRequests = yield select(getAvailabilityRequests);
	const fromDate = yield select(getFromDate);
	const patient = yield select(getCurrentPatient);
	const practice = yield select(getCurrentLocation);

	const requestParams = {
		practiceId: params.locationId,
		availabilityRequests,
		fromDate,
		patientId: patient.id ? patient.id : patient,
		patientNote: params?.patientNote,
	};

	const { response, errors, error } = yield call(
		api.saveBooking,
		requestParams,
	);
	if (response) {
		yield put(actions.saveBookingSuccess(response));
		if (successUrl) {
			if (practice?.account === "caredentistry") {
				window.open(successUrl, "_blank");
			} else {
				window.top.location.href = successUrl;
			}
		}
		yield put(push("complete"));
	} else {
		Raven.captureMessage("Online booking submission error", {
			extra: requestParams,
		});
		message.error(_.join(errors, ", "));
		yield put(actions.saveBookingFailure(error));
	}
}

export function* getPaymentIntentSaga({ practiceId, patientId }) {
	const { response, error } = yield call(api.getPaymentIntent, {
		practiceId,
		patientId,
	});
	if (response) {
		yield put(actions.getPaymentIntentSuccess(response));
	} else {
		yield put(actions.getPaymentIntentFailure(error));
	}
}

export function* reviewBookingSaga({ practiceId }) {
	const availabilityRequests = yield select(getAvailabilityRequests);
	const fromDate = yield select(getFromDate);

	const { response, error } = yield call(api.reviewBooking, {
		practiceId,
		availabilityRequests,
		fromDate,
	});
	if (response) {
		yield put(actions.reviewBookingSuccess(response));
	} else {
		yield put(actions.reviewBookingFailure(error));
	}
}

export function* cancelReservationSaga({ practiceId, availabilityRequests }) {
	const { response, error } = yield call(api.cancelReservation, {
		practiceId,
		availabilityRequests,
	});
	if (response) {
		yield put(actions.cancelReservationSuccess(response));
		yield put(
			actions.updateItem(
				{ ...availabilityRequests, reservationKey: null },
				practiceId,
			),
		);
	} else {
		yield put(actions.cancelReservationFailure(error));
	}
}

export function* makePaymentRequestSaga({
	practiceId,
	patientId,
	card,
	availabilityRequests,
	bookingDate,
	patientNote,
	successUrl,
}) {
	const { response, errors } = yield call(api.makePayment, {
		practiceId,
		patientId,
		card,
		availabilityRequests,
		bookingDate,
		patientNote,
	});

	const practice = yield select(getCurrentLocation);

	if (response) {
		yield put(actions.makePaymentSuccess(response));
		if (successUrl) {
			if (practice?.account === "caredentistry") {
				window.open(successUrl, "_blank");
			} else {
				window.top.location.href = successUrl;
			}
		}

		yield put(push("complete"));
	} else {
		Raven.captureMessage("Online booking submission error", {
			extra: availabilityRequests,
		});
		message.error("Appointment slot is no longer available.");
		yield put(actions.makePaymentFailure(errors));
	}
}

export default function* root() {
	yield all([
		yield takeLatest(types.SAVE_BOOKING_REQUEST, saveBookingSaga),
		yield takeLatest(types.REVIEW_BOOKING_REQUEST, reviewBookingSaga),
		yield takeLatest(types.GET_PAYMENT_INTENT, getPaymentIntentSaga),
		yield takeLatest(types.CANCEL_RESERVATION_REQUEST, cancelReservationSaga),
		yield takeLatest(types.MAKE_PAYMENT_REQUEST, makePaymentRequestSaga),
	]);
}
