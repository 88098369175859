import React, { useState } from "react";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import "antd-country-phone-input/dist/index.css";
import en from "world_countries_lists/data/countries/en/world.json";

const InternationalPhoneInput = ({
  onChange,
  input,
  onBlur,
  children,
  value,
  ...rest
}) => {
  const {
    regionCode,
    regionShort,
    accountRegionShort,
    accountRegionCode,
    setRegionCode,
    setRegionShort,
  } = rest;
  const [short, setShort] = useState(regionShort || accountRegionShort);
  const [code, setCode] = useState(regionCode || accountRegionCode);

  return (
    <ConfigProvider
      locale={en}
      areaFilter={(area) =>
        ["Australia", "New Zealand", "Singapore", "Philippines"].includes(
          area.name
        )
      }
    >
      <CountryPhoneInput
        defaultValue={{ short: short || "AU", code: code || 61, phone: value }}
        name="mobilePhone"
        placeholder="Mobile number"
        autocomplete="off"
        value={{ short, code, phone: value }}
        {...rest}
        onChange={(value) => {
          setShort(value.short);
          setCode(value.code);
          onChange(value.phone);
          setRegionCode(value);
          setRegionShort(value);
        }}
        inline
      />
    </ConfigProvider>
  );
};

export default InternationalPhoneInput;
