import * as constants from "./constants";

export const saveBookingRequest = (params, successUrl) => ({
	type: constants.SAVE_BOOKING_REQUEST,
	params,
	successUrl,
});

export const saveBookingSuccess = (response) => ({
	type: constants.SAVE_BOOKING_SUCCESS,
	response,
});

export const saveBookingFailure = (errors) => ({
	type: constants.SAVE_BOOKING_FAILURE,
	errors,
});

export const startBookingProcess = () => ({
	type: constants.START_BOOKING_PROCESS,
});

export const toggleItem = (item) => ({
	type: constants.TOGGLE_ITEM,
	item,
});

export const updateItem = (item, practiceId) => ({
	type: constants.UPDATE_ITEM,
	item,
	practiceId,
});

export const changeFromDate = (practiceId, newDate) => ({
	type: constants.CHANGE_FROM_DATE,
	practiceId,
	newDate,
});

export const changePatientNote = (note) => ({
	type: constants.CHANGE_PATIENT_NOTE,
	note,
});

export const clearBooking = () => ({
	type: constants.CLEAR_BOOKING,
});

export const makePaymentRequest = ({
	practiceId,
	patientId,
	card,
	availabilityRequests,
	bookingDate,
	patientNote,
	successUrl,
}) => ({
	type: constants.MAKE_PAYMENT_REQUEST,
	practiceId,
	patientId,
	card,
	availabilityRequests,
	bookingDate,
	patientNote,
	successUrl,
});

export const makePaymentSuccess = (response) => ({
	type: constants.MAKE_PAYMENT_SUCCESS,
	response,
});

export const makePaymentFailure = (errors) => ({
	type: constants.MAKE_PAYMENT_FAILURE,
	errors,
});

export const reviewBookingRequest = (practiceId) => ({
	type: constants.REVIEW_BOOKING_REQUEST,
	practiceId,
});

export const reviewBookingSuccess = (response) => ({
	type: constants.REVIEW_BOOKING_SUCCESS,
	response,
});

export const reviewBookingFailure = (errors) => ({
	type: constants.REVIEW_BOOKING_FAILURE,
	errors,
});

export const udpateAvailabilityRequests = (
	practitioners,
	reasonId,
	practiceId,
) => {
	let requests = [];
	if (reasonId) {
		requests = practitioners.map((p) => ({
			reasonId,
			practitionerId: p.id,
		}));
	} else {
		// TODO improve this block.
		// This is returns a single practice professional with services he provides.
		let p = practitioners[0];
		if (p) {
			const { appointmentReasons } = p;
			requests = appointmentReasons.map((apptRsn) => ({
				reasonId: apptRsn.id,
				practitionerId: p.id,
			}));
		}
	}

	return {
		type: constants.UPDATE_AVAILABILITY_REQUESTS,
		requests,
		practiceId,
	};
};

export const setCurrentPatient = (patient) => ({
	type: constants.SET_CURRENT_PATIENT,
	patient,
});

export const getPaymentIntent = (practiceId, patientId) => ({
	type: constants.GET_PAYMENT_INTENT,
	practiceId,
	patientId,
});

export const getPaymentIntentSuccess = (response) => ({
	type: constants.GET_PAYMENT_INTENT_SUCCESS,
	response,
});

export const getPaymentIntentFailure = (errors) => ({
	type: constants.GET_PAYMENT_INTENT_FAILURE,
	errors,
});

export const clearCurrentPatient = () => ({
	type: constants.CLEAR_CURRENT_PATIENT,
});

export const deleteRequestsUnlessValidSlot = () => ({
	type: constants.DELETE_REQUEST_UNLESS_VALID_SLOT,
});

export const cancelReservationRequest = (practiceId, availabilityRequests) => ({
	type: constants.CANCEL_RESERVATION_REQUEST,
	practiceId,
	availabilityRequests,
});

export const cancelReservationSuccess = (response) => ({
	type: constants.CANCEL_RESERVATION_SUCCESS,
	response,
});

export const cancelReservationFailure = (errors) => ({
	type: constants.CANCEL_RESERVATION_FAILURE,
	errors,
});

export const openPaymentDrawer = (state) => ({
	type: constants.OPEN_PAYMENT_DRAWER,
	state,
});

export const closePaymentDrawer = (state) => ({
	type: constants.CLOSE_PAYMENT_DRAWER,
	state,
});
