/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Typography, Collapse, Button, List, Space, Input } from "antd";
import { useDispatch, connect, useSelector } from "react-redux";
import * as actions from "./actions";
import {
	getReviewAppointments,
	getCurrentPatient,
	getSaveBookingState,
} from "./reducers";
import { getCurrentLocation } from "../Location/reducers";
import Payment from "./Payment";
import {
	CalendarOutlined,
	ClockCircleOutlined,
	UserOutlined,
	CreditCardOutlined,
} from "@ant-design/icons";
import { changePatientNote } from "../Booking/actions";
import { getAvailabilityRequests } from "../Booking/reducers";
import { ButtonSize, darkGrey, lightGrey } from "../../GlobalConfig";
import Spacer from "../../components/Spacer";
import ContentContainer from "../../components/ContentContainer";
import FullNameWithTitleLabel from "../../components/FullNameWithTitleLabel";
import { isMobile } from "react-device-detect";

const { Title, Text } = Typography;
const { Panel } = Collapse;

const Confirm = ({
	currentPractice,
	// currentPatient,
	reviewBookingRequest,
	// getPaymentIntent,
	reviewBookings,
	match,
	// location,
	saveBookingRequest,
	saveBookingState,
	startBookingProcess,
	// availabilityRequests,
	// toggleItem,
	deleteRequestsUnlessValidSlot,
	openPaymentDrawer,
}) => {
	const { params } = match;

	const dispatch = useDispatch();
	const patientNote = useSelector((state) => state.booking.patientNote);

	React.useEffect(() => {
		reviewBookingRequest(params.locationId);
		// if (currentPatient) {
		//   getPaymentIntent(params.locationId, currentPatient.id);
		// }
		deleteRequestsUnlessValidSlot();
	}, []);

	const paymentTotal = React.useMemo(() => {
		let sumTotal = 0;

		if (!reviewBookings) return sumTotal;

		reviewBookings.map(({ appointmentReason }) => {
			return (sumTotal += appointmentReason.price);
		});

		return sumTotal;
	}, [reviewBookings]);

	const handleMaybePay = () => {
		if (currentPractice.canReceiveOnlinePayments && paymentTotal > 0) {
			openPaymentDrawer();
		} else {
			dispatch(startBookingProcess());
			saveBookingRequest(
				{ ...params, patientNote },
				currentPractice?.successUrl,
			);
		}
	};

	const confirmationButtonText = () => {
		return currentPractice.canReceiveOnlinePayments && paymentTotal > 0
			? "Pay and Confirm"
			: "Confirm Booking";
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		dispatch(changePatientNote(e.target.value))
	};

	return (
		<ContentContainer
			pageTitle="Review"
			step="5"
			stepPercentage="90"
			showNext={false}
			extra={
				reviewBookings &&
				reviewBookings &&
				reviewBookings.length > 0 && (
					<Button
						size={ButtonSize}
						loadingState={saveBookingState}
						loading={saveBookingState}
						type="primary"
						onClick={() => handleMaybePay()}
					>
						{confirmationButtonText()}
					</Button>
				)
			}
		>
			<Payment practiceId={params.locationId} paymentTotal={paymentTotal} />

			<Card
				extra={
					!isMobile &&
					reviewBookings &&
					reviewBookings &&
					reviewBookings.length > 0 && (
						<Button
							size={ButtonSize}
							loadingState={saveBookingState}
							loading={saveBookingState}
							type="primary"
							onClick={() => handleMaybePay()}
						>
							{confirmationButtonText()}
						</Button>
					)
				}
				size="small"
				headStyle={{ background: lightGrey }}
				title="Please check your booking details"
			>
				<List size="large">
					{reviewBookings?.map(
						({ practitioner, appointmentReason, date, startTime }) => (
							<List.Item>
								<Space direction="vertical">
									<Title style={{ padding: 0, margin: 0 }} level={4}>
										{appointmentReason.name}{" "}
									</Title>
									{appointmentReason.description > null && (
										<Text type="secondary">
											{appointmentReason.description}
										</Text>
									)}
									<Space size="large" wrap>
										<Space size={4}>
											<CalendarOutlined style={{ color: darkGrey }} /> {date}
										</Space>

										<Space size={4}>
											<Text>
												<ClockCircleOutlined style={{ color: darkGrey }} />{" "}
												{startTime}
											</Text>
											<Text type="secondary">
												for {appointmentReason.minutes} Mins{" "}
											</Text>
										</Space>
										{appointmentReason.price > 0 && (
											<Space size={4}>
												<CreditCardOutlined style={{ color: darkGrey }} />$
												{appointmentReason.price?.toFixed(2)}
											</Space>
										)}

										<Space size={4}>
											<UserOutlined style={{ color: darkGrey }} />{" "}
											<FullNameWithTitleLabel
												name={practitioner.fullNameWithTitle}
											/>
										</Space>
										{appointmentReason.description > null && (
											<Collapse ghost defaultActiveKey="1">
												<Panel header="Additional Info" key="1" />
											</Collapse>
										)}
									</Space>
								</Space>
							</List.Item>
						),
					)}
				</List>
				<Input.TextArea
					value={patientNote}
					onChange={onChange}
					rows={4}
					placeholder="When booking online, please add any specific details or preferences here. This helps our staff prepare for your visit. Thank you."
					maxLength={250}
					showCount
					style={{ resize: 'none' }}
				/>
				<Spacer />

				<Card
					bodyStyle={{
						border: darkGrey,
						background: lightGrey,
						borderRadius: 4,
					}}
				>
					{currentPractice?.termsAndConditions && (
						<>
							<Title level={5} type="secondary">
								Terms and Conditions{" "}
							</Title>{" "}
							<span
								dangerouslySetInnerHTML={{
									__html: currentPractice.termsAndConditions,
								}}
							/>
						</>
					)}
				</Card>
			</Card>
		</ContentContainer>
	);
};

const mapStateToProps = (state) => ({
	reviewBookings: getReviewAppointments(state.booking),
	currentPractice: getCurrentLocation(state.locations),
	saveBookingState: getSaveBookingState(state.booking),
	availabilityRequests: getAvailabilityRequests(state.booking),
	currentPatient: getCurrentPatient(state.booking),
});

export default connect(mapStateToProps, {
	...actions,
	// toggleItem,
	// deleteRequestsUnlessValidSlot,
	// openPaymentDrawer,
})(Confirm);
