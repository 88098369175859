import { all, call, select, put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as bookingActions from "../Booking/actions";
import { message } from "antd";

import * as types from "./constants";
import {
	CHANGE_FROM_DATE,
	UPDATE_ITEM,
	UPDATE_AVAILABILITY_REQUESTS,
} from "../Booking/constants";
import { getAvailabilityRequests, getFromDate } from "../Booking/reducers";
import api from "./api";

export function* fetchProfessionalAvaialabilitySaga({ practiceId }) {
	const availabilityRequests = yield select(getAvailabilityRequests);
	const fromDate = yield select(getFromDate);

	const { response, error } = yield call(api.fetchProfessionalAvailability, {
		practiceId,
		availabilityRequests,
		fromDate,
	});
	if (response) {
		yield put(actions.fetchProfessionalAvailabilitySuccess(response));
	} else {
		yield put(actions.fetchProfessionalAvailabilityFailure(error));
	}
}

export function* fetchAvailabilityBetweenRangeSaga({
	practiceId,
	fromDate,
	toDate,
}) {
	const availabilityRequests = yield select(getAvailabilityRequests);
	// const fromDate = yield select(getFromDate);
	const { response, error } = yield call(api.fetchAvailabilityBetweenRange, {
		practiceId,
		availabilityRequests,
		fromDate,
		toDate,
	});
	if (response) {
		yield put(actions.fetchAvailabilityBetweenRangeSuccess(response));
	} else {
		yield put(actions.fetchAvailabilityBetweenRangeFailure(error));
	}
}

export function* reservationSaga({ practiceId, requestItem }) {
	const response = yield call(api.reservation, {
		practiceId,
		requestItem,
	});

	if (!response.errors) {
		yield put(actions.reservationSuccess(response));
		yield put(
			bookingActions.updateItem(
				{ ...requestItem, reservationKey: response.id },
				practiceId,
			),
		);
	} else {
		message.error(response.errors.map((e) => e).join(", "));
		yield put(actions.reservationFailure(response.error));
		yield put(
			bookingActions.updateItem(
				{ ...requestItem, startTime: null },
				practiceId,
			),
		);
	}
}

export function* deleteReservationSaga({ practiceId, requestItem }) {
	const { response, error } = yield call(api.deleteReservation, {
		practiceId,
		requestItem,
	});
	if (response) {
		yield put(actions.deleteReservationSuccess(response));
		yield put(
			bookingActions.updateItem(
				{ ...requestItem, reservationKey: null },
				practiceId,
			),
		);
	} else {
		yield put(actions.deleteReservationFailure(error));
	}
}

export default function* root() {
	yield all([
		yield takeLatest(
			[
				types.FETCH_PROFESSIONAL_AVAILABILITY_REQUEST,
				CHANGE_FROM_DATE,
				UPDATE_ITEM,
				UPDATE_AVAILABILITY_REQUESTS,
			],
			fetchProfessionalAvaialabilitySaga,
		),
	]);
	yield all([yield takeLatest(types.RESERVATION_REQUEST, reservationSaga)]);
	yield all([
		yield takeLatest(
			types.FETCH_AVAILABILITY_BETWEEN_RANGE_REQUEST,
			fetchAvailabilityBetweenRangeSaga,
		),
	]);
	yield all([
		yield takeLatest(types.DELETE_RESERVATION_REQUEST, deleteReservationSaga),
	]);
}
