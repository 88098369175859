import { combineReducers } from 'redux';
import createReducer from 'utils/createReducer';
import * as types from './constants';
import { UPDATE_ITEM, CLEAR_BOOKING } from '../Booking/constants';


const availability = createReducer([])({
	[types.FETCH_PROFESSIONAL_AVAILABILITY_SUCCESS]: (state, { response }) => response,
	[CLEAR_BOOKING]: () => []
});


const availabilityRange = createReducer([])({
	[types.FETCH_AVAILABILITY_BETWEEN_RANGE_SUCCESS]: (state, { response }) => response,
	[CLEAR_BOOKING]: () => []
});


const availabilityLoadingState = createReducer(false)({
	[UPDATE_ITEM]: () => true,
	[types.FETCH_PROFESSIONAL_AVAILABILITY_SUCCESS]: () => false,
	[types.FETCH_PROFESSIONAL_AVAILABILITY_REQUEST]: () => true
});


const availabilityRangeLoadingState = createReducer(false)({	
	[types.FETCH_AVAILABILITY_BETWEEN_RANGE_REQUEST]: () => true,
	[types.FETCH_AVAILABILITY_BETWEEN_RANGE_SUCCESS]: () => false
});

export const getAvailability = (state) => {
	if (state) {
		return state.availability;
	}
};


export const getAvailabilityRange = (state) => {
	if (state) {
		return state.availabilityRange;
	}
};


export const getAvailabilityLoadingState = (state) => {
	if (state) {
		return state.availabilityLoadingState;
	}
};


export const getAvailabilityRangeLoadingState = (state) => {
	if (state) {
		return state.availabilityRangeLoadingState;
	}
};

export default combineReducers({
	availability,
	availabilityRange,
	availabilityLoadingState,
	availabilityRangeLoadingState
});
