import { all, call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as types from "./constants";
import api from "./api";
import { udpateAvailabilityRequests } from "../Booking/actions";

export function* fetchAppointmentReasonsSaga({ practiceId }) {
  const { response, error } = yield call(api.fetchAppointmentReasons, { practiceId });
  if (response) {
    yield put(actions.fetchCatalogueItemsSuccess(response));
  } else {
    yield put(actions.fetchCatalogueItemsFailure(error));
  }
}

export function* fetchPracticeProfessionalAppointmentReasonSaga({ practiceId, reasonId, practitionerId }) {
  const { response, error } = yield call(api.fetchPracticeProfessionalAppointmentReasons, {
    practiceId,
    reasonId,
    practitionerId,
  });
  if (response) {
    yield put(actions.fetchPracticeProfessionalAppointmentReasonsSuccess(response));
    if (reasonId || practitionerId) {
      reasonId = reasonId ? parseInt(reasonId) : "";
      yield put(udpateAvailabilityRequests(response, reasonId, practiceId));
    }
  } else {
    yield put(actions.fetchPracticeProfessionalAppointmentReasonsFailure(error));
  }
}

export default function* root() {
  yield all([
    yield takeLatest(types.FETCH_APPOINTMENT_REASONS_REQUEST, fetchAppointmentReasonsSaga),
    yield takeLatest(
      types.FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_REQUEST,
      fetchPracticeProfessionalAppointmentReasonSaga
    ),
  ]);
}
