import React from "react";
import { Checkbox } from "antd";

class CheckboxGroup extends React.Component {
  render() {
    const { options, onChange, defaultValue } = this.props;
    console.log(this.props);
    return (
      <Checkbox.Group
        defaultValue={defaultValue}
        options={options}
        onChange={(checkedValue) => onChange(checkedValue)}
        // onChange={(checkedValue) => {
        // 	const newValue = [ ...value ];
        // 	if (event.target.checked) {
        // 		newValue.push(option.value);
        // 	} else {
        // 		newValue.splice(newValue.indexOf(option.value), 1);
        // 	}

        // 	return onChange(newValue);
        // }}
      />
    );
  }
}

export default CheckboxGroup;
