import { combineReducers } from 'redux';

import createReducer from 'utils/createReducer';

import { FETCH_PRACTITIONERS_SUCCESS } from './constants';

const byIdSuccess = (state, { response }) => response.entities.practitioners || {};
const allIdsSuccess = (state, { response }) => response.result || [];

const byId = createReducer({})({
	[FETCH_PRACTITIONERS_SUCCESS]: byIdSuccess
});

const allIds = createReducer([])({
	[FETCH_PRACTITIONERS_SUCCESS]: allIdsSuccess
});

export const getPractitioner = (state, id) => state.byId[id];
export const getPractitioners = (state) => {
	if (state) {
		return state.allIds.map((id) => state.byId[id]);
	}
	return [];
};

export default combineReducers({
	byId,
	allIds
});
