import { all, call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as types from "./constants";

import api from "./api";

export function* fetchLocationsSaga({ account }) {
  const { response, error } = yield call(api.fetchLocations, { account });
  if (response) {
    yield put(actions.fetchLocationsSuccess(response));
  } else {
    yield put(actions.fetchLocationsFailure(error));
  }
}

export function* fetchLocationSaga({ locationId }) {
  const { response, error } = yield call(api.fetchLocation, { locationId });
  if (response) {
    yield put(actions.fetchCurrentLocationSuccess(response));
  } else {
    yield put(actions.fetchCurrentLocationFailure(error));
  }
}

export default function* root() {
  yield all([
    yield takeLatest(types.FETCH_LOCATIONS_REQUEST, fetchLocationsSaga),
  ]);
  yield all([
    yield takeLatest(types.FETCH_CURRENT_LOCATION_REQUEST, fetchLocationSaga),
  ]);
}
