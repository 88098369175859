/* eslint-disable no-unneeded-ternary */
import { isMobile } from "react-device-detect";

/* eslint-disable camelcase */

export const ButtonSize = "middle";
export const ButtonSizeComponent = "middle";
export const CardBorderd = isMobile ? false : true;
export const CardSize = isMobile ? "small" : "default";
export const TableSize = isMobile ? "small" : "middle";
export const AvatarSize = isMobile ? "18" : "28";
export const SpaceSize = isMobile ? "small" : "middle";

// used when grouping actions as buttons
export const SpaceSizeActionGroup = isMobile ? 2 : 4;
export const titleSize = isMobile ? 4 : 3;
export const ButtonBlockMobile = isMobile ? true : false;
export const DividerOrientation = isMobile ? "center" : "left";
export const DrawerWidth = isMobile ? "100%" : 700;
export const DescriptionsLabelColor = "#8f8f8f";
export const DescriptionsSize = "small";
export const TabsSize = isMobile ? "small" : undefined;
export const DescriptionsLabelWidth = "180px";
export const ComponentTitleResponsive = isMobile ? 5 : 3;
export const ShortHandDateandTime = "DD-MM-YYYY [at] h:mm A";
export const defaultVerticalGutter = isMobile ? 8 : 12;
export const defaultHorizontalGutter = isMobile ? 8 : 12;
// Used for backgrounds or as accent background
export const lightGrey = "#fafafa";
export const lightGrey2 = "#f6f6f6";
export const lightGrey3 = "#e9e9e9";
export const darkGrey = "#bebeba";

// main nav
export const mainTheme = "dark";
export const mainThemeBG = "#0c2556";

// Green
export const greenLight = "#d1f8b1";
export const greenDark = "#02c5ac";

// Orange
export const orangeDark = "#ff7557";
export const orangeLight = "#fff4f2";

// Aqua
export const aquaDark = "#0d7ea0";

export const cyanDark = "#87e2db";
export const cyanLight = "#f5fdfc";

export const volcanoDark = "#ff7557";

export const purpleDark = "#7856ff";

// Yellow
export const yellowDark = "#ffec3d";

// Green that matches Zavy
export const primaryLight = "#f3f0ff";
export const primaryMid = "#d7d9ff";
export const primaryDark = "#635bff";

export const redLight = "#fec0bb";
export const redDark = "#ff3e71";

export const defaultAppointmentColour = "#d7d9ff";
