import React from "react";
import { Button, Space } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./actions";
import { getConfirmedAppointments } from "./reducers";
import { getCurrentLocation } from "../Location/reducers";
import LocationCard from "components/LocationCard";

import Spacer from "../../components/Spacer";
import ContentContainer from "../../components/ContentContainer";
import { CheckCircleFilled } from "@ant-design/icons";
import { primaryDark } from "../../GlobalConfig";

const Complete = ({ currentPractice, currentLocation }) => {
	const { name, phone, logo, location, coverPhoto } = currentLocation;

	return (
		<ContentContainer
			step="5"
			stepPercentage="100"
			showNext={false}
			pageTitle={
				<Space>
					<CheckCircleFilled style={{ color: primaryDark }} />
					Booking Successful
				</Space>
			}
		>
			<div style={{ maxWidth: 550, marginLeft: "auto", marginRight: "auto" }}>
				<LocationCard
					{...{ name, phone, logo, location, coverPhoto, hideBookButton: true }}
				/>
				<Spacer />
				<Button block>
					<Link to="reasons">Book another</Link>
				</Button>
				<Spacer />
				<div style={{ textAlign: "center", padding: 8, background: "#fafafa" }}>
					You may receive SMS and Email confirmation. Feel free to contact us if
					you have any questions.
				</div>
			</div>
		</ContentContainer>
	);
};

const mapStateToProps = (state) => ({
	confirmedAppointments: getConfirmedAppointments(state.booking),
	currentPractice: getCurrentLocation(state.booking),
	currentLocation: getCurrentLocation(state.locations),
});

export default connect(mapStateToProps, actions)(Complete);
