/* eslint-disable import/no-anonymous-default-export */
import {
	handleResponse,
	errorResponse,
	makePOSTRequest,
	makePATCHRequest,
	makeGETRequest,
} from "utils/apiRequests";

import { apiUrl, sessionApiUrl } from "../../config";

const login = ({ user }) => {
	const url = sessionApiUrl()("login");
	return makePOSTRequest(url)({ user })
		.then(handleResponse)
		.catch(errorResponse);
};

const resetPassword = ({ user }) => {
	const url = sessionApiUrl()("reset_password");
	return makePATCHRequest(url)({ user })
		.then(handleResponse)
		.catch(errorResponse);
};

const signup = ({ user }) => {
	const url = sessionApiUrl()("register");
	return makePOSTRequest(url)({ patients_registration_form: user })
		.then(handleResponse)
		.catch(errorResponse);
};

const addPatient = ({ account, practiceId, user }) => {
	const url = apiUrl(account)(`/practices/${practiceId}/patients`);
	return makePOSTRequest(url)({ ...user })
		.then(handleResponse)
		.catch(errorResponse);
};

const generateOtp = ({ user }) => {
	const url = apiUrl()(`/otp`);
	return makePOSTRequest(url)({
		...user,
	})
		.then(handleResponse)
		.catch(errorResponse);
};

const verifyOtp = ({ user }) => {
	const url = apiUrl()(`/otp/${user.otpKey}/verify`);

	return makePOSTRequest(url)({
		...user,
	})
		.then(handleResponse)
		.catch(errorResponse);
};

// const generateResubmitOtp = ({ mobilePhone }) => {
//   const url = apiUrl()(`/otp/resubmit`);
//   return makePOSTRequest(url).then(handleResponse).catch(errorResponse);
// };

const fetchPatients = ({ account, practiceId, user }) => {
	const { email, mobilePhone } = user;

	const params = {
		by_email: email,
		by_mobile_phone: mobilePhone,
	};

	const url = apiUrl(account)(`/practices/${practiceId}/patients`);

	return makeGETRequest(url, { params })
		.then(handleResponse)
		.catch(errorResponse);
};

export default {
	login,
	resetPassword,
	signup,
	addPatient,
	generateOtp,
	verifyOtp,
	fetchPatients,
	// generateResubmitOtp,
};
