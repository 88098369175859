import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { reducer as formReducer } from "redux-form";

import { LocationReducer } from "modules/Location";
import { AppointmentReasonReducer } from "modules/Service";
import { PractitionerReducer } from "modules/Practitioner";
// import { UserReducer } from 'modules/User';
import { BookingReducer } from "modules/Booking";
import { AvailabilityReducer } from "./modules/Calendar";
import { SessionReducer } from "./modules/Session";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    locations: LocationReducer,
    appointmentReasons: AppointmentReasonReducer,
    practitioners: PractitionerReducer,
    session: SessionReducer,
    booking: BookingReducer,
    availability: AvailabilityReducer,
    form: formReducer,
  });
export default createRootReducer;
