/* eslint-disable import/no-anonymous-default-export */
import { account } from "helpers/account";
import { normalizeResponse, errorResponse, makeGETRequest, handleResponse } from "utils/apiRequests";
import { practicesListSchema } from "./schema";

import { apiUrl } from "../../config";

const fetchLocations = () => {
  const url = apiUrl(account)("/practices");
  const handleResponse = (response) => normalizeResponse(response, practicesListSchema);

  return makeGETRequest(url).then(handleResponse).catch(errorResponse);
};

const fetchLocation = ({ locationId }) => {
  const url = apiUrl(account)(`/practices/${locationId}`);
  return makeGETRequest(url).then(handleResponse).catch(errorResponse);
};

export default {
  fetchLocations,
  fetchLocation,
};
