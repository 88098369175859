import * as constants from "./constants";

export const loginRequest = (user) => ({
	type: constants.LOGIN_REQUEST,
	user,
});

export const loginSuccess = (response) => ({
	type: constants.LOGIN_SUCCESS,
	response,
});

export const loginFailure = (errors) => ({
	type: constants.LOGIN_FAILURE,
	errors,
});

export const resetPasswordRequest = (user) => ({
	type: constants.RESET_PASSWORD_REQUEST,
	user,
});

export const resetPasswordSuccess = (response) => ({
	type: constants.RESET_PASSWORD_SUCCESS,
	response,
});

export const resetPasswordFailure = (errors) => ({
	type: constants.RESET_PASSWORD_FAILURE,
	errors,
});

export const signupRequest = (user) => ({
	type: constants.SIGNUP_REQUEST,
	user,
});

export const signupSuccess = (response) => ({
	type: constants.SIGNUP_SUCCESS,
	response,
});

export const signupFailure = (errors) => ({
	type: constants.SIGNUP_FAILURE,
	errors,
});

export const addGuestRequest = (user) => ({
	type: constants.ADD_GUEST_REQUEST,
	user,
});

export const addGuestSuccess = (response) => ({
	type: constants.ADD_GUEST_SUCCESS,
	response,
});

export const addGuestFailure = (errors) => ({
	type: constants.ADD_GUEST_FAILURE,
	errors,
});

export const generateOtpRequest = (user) => ({
	type: constants.GENERATE_OTP_REQUEST,
	user,
});

export const generateOtpSuccess = (response) => ({
	type: constants.GENERATE_OTP_SUCCESS,
	response,
});

export const generateOtpFailure = (errors) => ({
	type: constants.GENERATE_OTP_FAILURE,
	errors,
});

export const clearOtpKey = () => ({
	type: constants.CLEAR_OTP_KEY,
});

export const verifyOtpRequest = (account, practiceId, user) => ({
	type: constants.VERIFY_OTP_REQUEST,
	account,
	practiceId,
	user,
});

export const verifyOtpSuccess = (response) => ({
	type: constants.VERIFY_OTP_SUCCESS,
	response,
});

export const verifyOtpFailure = (errors) => ({
	type: constants.VERIFY_OTP_FAILURE,
	errors,
});

export const fetchPatientsRequest = (account, practiceId) => ({
	type: constants.FETCH_PATIENTS_REQUEST,
	account,
	practiceId,
});

export const fetchPatientsSuccess = (response) => ({
	type: constants.FETCH_PATIENTS_SUCCESS,
	response,
});

export const fetchPatientsFailure = (errors) => ({
	type: constants.FETCH_PATIENTS_FAILURE,
	errors,
});

export const addPatientRequest = (
	account,
	practiceId,
	formValues,
	otpMethod,
) => ({
	type: constants.ADD_PATIENT_REQUEST,
	account,
	practiceId,
	formValues,
	otpMethod,
});

export const addPatientSuccess = (response) => ({
	type: constants.ADD_PATIENT_SUCCESS,
	response,
});

export const addPatientFailure = (errors) => ({
	type: constants.ADD_PATIENT_FAILURE,
	errors,
});

export const clearPatients = () => ({
	type: constants.CLEAR_PATIENTS,
});
