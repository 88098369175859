export const FETCH_APPOINTMENT_REASONS_REQUEST =
  "location/FETCH_APPOINTMENT_REASONS_REQUEST";
export const FETCH_APPOINTMENT_REASONS_SUCCESS =
  "location/FETCH_APPOINTMENT_REASONS_SUCCESS";
export const FETCH_APPOINTMENT_REASONS_FAILURE =
  "location/FETCH_APPOINTMENT_REASONS_FAILURE";

export const FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_REQUEST =
  "location/FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_REQUEST";
export const FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_SUCCESS =
  "location/FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_SUCCESS";
export const FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_FAILURE =
  "location/FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_FAILURE";
