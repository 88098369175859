/* eslint-disable import/no-anonymous-default-export */
import {
	errorResponse,
	makePOSTRequest,
	makeGETRequest,
	handleResponse,
	makePATCHRequest,
} from "utils/apiRequests";
import { apiUrl } from "../../config";

import { account } from "../../helpers/account";

const saveBooking = ({
	practiceId,
	availabilityRequests,
	fromDate,
	patientId,
	patientNote,
}) => {
	const url = apiUrl(account)(`/practices/${practiceId}/appointments`);
	return makePOSTRequest(url)({
		online_booking_vacancy_form: {
			availabilityRequests,
			fromDate,
			patientId,
			patientNote,
		},
	})
		.then(handleResponse)
		.catch(errorResponse);
};

const reviewBooking = ({ practiceId, availabilityRequests, fromDate }) => {
	const url = apiUrl(account)(`/practices/${practiceId}/review_bookings`);
	return makePOSTRequest(url)({
		online_booking_vacancy_form: { availabilityRequests, fromDate },
	})
		.then(handleResponse)
		.catch(errorResponse);
};

export const getPaymentIntent = ({ practiceId, patientId }) => {
	const url = apiUrl(account)(
		`/practices/${practiceId}/patients/${patientId}/setup_intent`,
	);
	return makeGETRequest(url).then(handleResponse).catch(errorResponse);
};

const cancelReservation = ({ practiceId, availabilityRequests }) => {
	const url = apiUrl(account)(
		`/practices/${practiceId}/appointment_reservations/cancel`,
	);
	return makePATCHRequest(url)({
		availabilityRequests,
	})
		.then(handleResponse)
		.catch(errorResponse);
};

const makePayment = ({
	practiceId,
	patientId,
	card,
	availabilityRequests,
	patientNote,
	bookingDate,
}) => {
	const url = apiUrl(account)(
		`/practices/${practiceId}/appointments/make_payment`,
	);
	return makePOSTRequest(url)({
		card,
		online_booking_vacancy_form: {
			availabilityRequests,
			patientNote,
		},
		bookingDate,
		patientId,
	})
		.then(handleResponse)
		.catch(errorResponse);
};

export default {
	saveBooking,
	reviewBooking,
	cancelReservation,
	makePayment,
};
