export const SAVE_BOOKING_REQUEST = "booking/SAVE_BOOKING_REQUEST";
export const SAVE_BOOKING_SUCCESS = "booking/SAVE_BOOKING_SUCCESS";
export const SAVE_BOOKING_FAILURE = "booking/SAVE_BOOKING_FAILURE";

export const TOGGLE_ITEM = "booking/TOGGLE_ITEM";
export const UPDATE_ITEM = "booking/UPDATE_ITEM";

export const CHANGE_PATIENT_NOTE = "booking/CHANGE_PATIENT_NOTE";
export const CHANGE_FROM_DATE = "booking/CHANGE_FROM_DATE";
export const CLEAR_BOOKING = "booking/CLEAR_BOOKING";

export const REVIEW_BOOKING_REQUEST = "booking/REVIEW_BOOKING_REQUEST";
export const REVIEW_BOOKING_FAILURE = "booking/REVIEW_BOOKING_FAILURE";
export const REVIEW_BOOKING_SUCCESS = "booking/REVIEW_BOOKING_SUCCESS";

export const UPDATE_AVAILABILITY_REQUESTS =
	"booking/UPDATE_AVAILABILITY_REQUESTS";

export const SET_CURRENT_PATIENT = "booking/SET_CURRENT_PATIENT";
export const CLEAR_CURRENT_PATIENT = "booking/CLEAR_CURRENT_PATIENT";
export const DELETE_REQUEST_UNLESS_VALID_SLOT =
	"booking/DELETE_REQUEST_UNLESS_VALID_SLOT";

export const CANCEL_RESERVATION_REQUEST = "booking/CANCEL_RESERVATION_REQUEST";
export const CANCEL_RESERVATION_SUCCESS = "booking/CANCEL_RESERVATION_SUCCESS";
export const CANCEL_RESERVATION_FAILURE = "booking/CANCEL_RESERVATION_FAILURE";

export const OPEN_PAYMENT_DRAWER = "booking/OPEN_PAYMENT_DRAWER";
export const CLOSE_PAYMENT_DRAWER = "booking/CLOSE_PAYMENT_DRAWER";

export const GET_PAYMENT_INTENT = "booking/GET_PAYMENT_INTENT";
export const GET_PAYMENT_INTENT_SUCCESS = "booking/GET_PAYMENT_INTENT_SUCCESS";
export const GET_PAYMENT_INTENT_FAILURE = "booking/GET_PAYMENT_INTENT_FAILURE";

export const MAKE_PAYMENT_REQUEST = "booking/MAKE_PAYMENT_REQUEST";
export const MAKE_PAYMENT_SUCCESS = "booking/MAKE_PAYMENT_SUCCESS";
export const MAKE_PAYMENT_FAILURE = "booking/MAKE_PAYMENT_FAILURE";

export const START_BOOKING_PROCESS = "booking/START_BOOKING_PROCESS";
