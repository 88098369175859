import { combineReducers } from "redux";

import createReducer from "utils/createReducer";

import {
  FETCH_LOCATIONS_SUCCESS,
  FETCH_CURRENT_LOCATION_SUCCESS,
} from "./constants";

const byIdSuccess = (state, { response }) => response.entities.practices || {};
const allIdsSuccess = (state, { response }) => response.result || [];

const byId = createReducer({})({
  [FETCH_LOCATIONS_SUCCESS]: byIdSuccess,
});

const allIds = createReducer([])({
  [FETCH_LOCATIONS_SUCCESS]: allIdsSuccess,
});

const currentLocation = createReducer({})({
  [FETCH_CURRENT_LOCATION_SUCCESS]: (state, { response }) => response,
});

export const getLocation = (state, id) => state.byId[id];
export const getLocations = (state) => {
  if (state) {
    return state.allIds.map((id) => state.byId[id]);
  }
  return [];
};

export const getCurrentLocation = (state) => {
  if (state) {
    return state.currentLocation;
  }
  return {};
};

export default combineReducers({
  byId,
  allIds,
  currentLocation,
});
