/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
// import SignupForm from "./SignupForm";
import ReCAPTCHA from "react-google-recaptcha";
import { accountBaseUrl } from "../../helpers/account";
import * as actions from "./actions";
import * as bookingActions from "modules/Booking/actions";
// import LoginForm from "./LoginForm";
import ExistingPatients from "./Patient/ExistingPatients";
import Guest from "./Guest";
import {
	getOtpKey,
	getOtpVerified,
	getPatients,
	getOtpVerificationRequestState,
} from "./reducers";
import {
	getCurrentPatient,
	getAvailabilityRequests,
} from "modules/Booking/reducers";
import { getCurrentLocation } from "../Location/reducers";
import NewPatientForm from "./Patient/NewPatient";
import { ArrowLeftOutlined } from "@ant-design/icons";

import ContentContainer from "../../components/ContentContainer";

function App({
	// loginRequest,
	match,
	// signupRequest,
	// addGuestRequest,
	generateOtpRequest,
	otpKey,
	verifyOtpRequest,
	isOtpverified,
	patients,
	setCurrentPatient,
	currentPatient,
	otpVerificationRequestState,
	deleteRequestsUnlessValidSlot,
	currentPractice,
	availabilityRequests,
	cancelReservationRequest,
	// clearPatients,
	clearOtpKey,
}) {
	const { params } = match;
	const practiceId = params.locationId;
	const account = params.account;

	const [otpMethod, setOtpMethod] = useState("sms");

	const recaptcha = React.useRef();

	const handleResubmit = (values) => {
		const captchaValue = recaptcha.current.getValue();
		generateOtpRequest(values, captchaValue);
	};

	const manageOtpMethod = (values) => {
		const captchaValue = recaptcha.current.getValue();

		const { mobileRegionCode, mobileRegionShort, otp } = values;

		const mobilePhone = otpMethod === "sms" ? values.mobilePhone : null;
		const email = otpMethod === "email" ? values.email : null;

		if (otpKey) {
			verifyOtpRequest(account, practiceId, {
				mobilePhone,
				mobileRegionCode,
				mobileRegionShort,
				email,
				otpKey,
				otpMethod,
				otp,
			});
		} else {
			generateOtpRequest(
				{
					mobilePhone,
					mobileRegionCode,
					mobileRegionShort,
					email,
					otpMethod,
				},
				captchaValue,
			);
		}
	};

	useEffect(() => {
		deleteRequestsUnlessValidSlot();
	}, []);

	return (
		<ContentContainer
			showNext={false}
			backButton={
				<Link
					style={{ color: "inherit" }}
					to={`${accountBaseUrl}/locations/${practiceId}/reasons`}
					onClick={() =>
						cancelReservationRequest(currentPractice.id, availabilityRequests)
					}
				>
					<Button
						type="text"
						shape="circle"
						disabled
						icon={<ArrowLeftOutlined />}
					/>
				</Link>
			}
			step={4}
			pageTitle="Verification"
			stepPercentage="80"
		>
			<Row gutter={[12, 12]} justify="space-around">
				{!isOtpverified && (
					<>
						<Col span={24}>
							<Guest
								recaptcha={recaptcha}
								backButton={
									<Button>
										{" "}
										<Link
											style={{ color: "inherit" }}
											to={`${accountBaseUrl}/locations/${practiceId}/reasons`}
											onClick={() =>
												cancelReservationRequest(
													currentPractice.id,
													availabilityRequests,
												)
											}
										>
											Back
										</Link>
									</Button>
								}
								onSubmit={manageOtpMethod}
								handleResubmit={handleResubmit}
								codeVerificationMethod={otpMethod}
								setCodeVerificationMethod={setOtpMethod}
								clearOtpKey={clearOtpKey}
							/>
						</Col>
						<Col>
							<ReCAPTCHA
								ref={recaptcha}
								sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
							/>
						</Col>
					</>
				)}

				{isOtpverified && patients && patients.length > 0 && (
					<Col span={24}>
						<>
							<ExistingPatients
								practiceId={practiceId}
								account={account}
								patients={patients}
								setCurrentPatient={setCurrentPatient}
								currentPatient={currentPatient}
							/>
						</>
					</Col>
				)}

				{isOtpverified &&
					patients &&
					patients.length <= 0 &&
					!otpVerificationRequestState && (
						<Col flex="650px">
							<NewPatientForm practiceId={practiceId} account={account} />
						</Col>
					)}

				{/* <Col span={12} className="pd-lg">
							<LoginForm onSubmit={(v) => loginRequest(v)} />
						</Col>
						<Col span={12} className="pd-lg grey-background">
							<SignupForm	onSubmit={(v) => signupRequest({ ...v, account, practiceId })}
							/>
						</Col> */}
			</Row>
		</ContentContainer>
	);
}

const mapStateToProps = (state) => ({
	otpKey: getOtpKey(state.session),
	isOtpverified: getOtpVerified(state.session),
	patients: getPatients(state.session),
	currentPatient: getCurrentPatient(state.booking),
	otpVerificationRequestState: getOtpVerificationRequestState(state.session),
	currentPractice: getCurrentLocation(state.locations),
	availabilityRequests: getAvailabilityRequests(state.booking),
});

export default connect(mapStateToProps, {
	...actions,
	...bookingActions,
})(App);
