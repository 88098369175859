import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./App.css";
import App from "./App";

Sentry.init({
	dsn: "https://11e059c43e1877ccee505f6dfbb0f2ae@o4505599540068352.ingest.us.sentry.io/4506897717919744",
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 1.0,
});
// import * as serviceWorker from './serviceWorker';
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
