import * as constants from './constants';

export const fetchProfessionalAvailabilityRequest = (practiceId) => ({
	type: constants.FETCH_PROFESSIONAL_AVAILABILITY_REQUEST,
	practiceId
});

export const fetchProfessionalAvailabilitySuccess = (response) => ({
	type: constants.FETCH_PROFESSIONAL_AVAILABILITY_SUCCESS,
	response
});

export const fetchProfessionalAvailabilityFailure = (errors) => ({
	type: constants.FETCH_PROFESSIONAL_AVAILABILITY_FAILURE,
	errors
});

export const reservationRequest = (requestItem, practiceId) => ({
	type: constants.RESERVATION_REQUEST,
	practiceId,
	requestItem
});

export const reservationSuccess = (response) => ({
	type: constants.RESERVATION_SUCCESS,
	response
});

export const reservationFailure = (errors) => ({
	type: constants.RESERVATION_FAILURE,
	errors
});

export const deleteReservationRequest = (requestItem, practiceId) => ({
	type: constants.DELETE_RESERVATION_REQUEST,
	practiceId,
	requestItem
});

export const deleteReservationSuccess = (response) => ({
	type: constants.DELETE_RESERVATION_SUCCESS,
	response
});

export const deleteReservationFailure = (errors) => ({
	type: constants.DELETE_RESERVATION_FAILURE,
	errors
});


export const fetchAvailabilityBetweenRangeRequest = (practiceId,fromDate,toDate) => ({
	type: constants.FETCH_AVAILABILITY_BETWEEN_RANGE_REQUEST,
	practiceId,
	fromDate,
	toDate
});

export const fetchAvailabilityBetweenRangeSuccess = (response) => ({
	type: constants.FETCH_AVAILABILITY_BETWEEN_RANGE_SUCCESS,
	response
});

export const fetchAvailabilityBetweenRangeFailure = (errors) => ({
	type: constants.FETCH_AVAILABILITY_BETWEEN_RANGE_FAILURE,
	errors
});