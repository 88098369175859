import React from 'react';
import { connect } from 'react-redux';

import * as actions from './actions';
import ResetPasswordForm from './ResetPasswordForm';

function App({ resetPasswordRequest, match }) {
	const { params } = match;
	const resetPasswordToken = params.token;
	return (
		<div>
			<ResetPasswordForm onSubmit={(v) => resetPasswordRequest(v)} initialValues={{ resetPasswordToken }} />
		</div>
	);
}

export default connect(null, actions)(App);
