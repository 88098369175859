import React from "react";
import {
	Row,
	Tag,
	Button,
	Descriptions,
	Result,
	Spin,
	Space,
	Typography,
	Avatar,
	Col,
} from "antd";
import { connect, useSelector } from "react-redux";
import RenderSlot from "./RenderSlot";
import * as actions from "./actions";
import * as bookingActions from "../Booking/actions";
import { getAvailability } from "./reducers";
import { getAvailabilityRequests } from "../Booking/reducers";
// import AvatarView from "components/AvatarView";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import {
	DeleteOutlined,
	UserOutlined,
	CalendarOutlined,
} from "@ant-design/icons";
// import { select } from "redux-saga/effects";
import { primaryDark } from "../../GlobalConfig";
import { isDesktop, isMobile } from "react-device-detect";
import Spacer from "../../components/Spacer";

const helperFindStartTime = (requests, reasonId, pId) =>
	requests.find(
		(rqst) => rqst.reasonId === reasonId && rqst.practitionerId === pId,
	);

function DisplayAvailableTime({
	date,
	collatedAvailabilityData,
	updateItem,
	availabilityRequests,
	practiceId,
	toggleItem,
	fetchProfessionalAvailabilityRequest,
	reservationRequest,
	deleteReservationRequest,
}) {
	let matchingRequest;

	// Find the requests whose startTime is selected
	const requestSlotTimeSelected = availabilityRequests.filter(
		(request) => request.startTime,
	);

	const loading = useSelector(
		(state) => state.availability.availabilityRangeLoadingState,
	);

	// match those with collated data, if already selected hide the service with other practitioners.
	collatedAvailabilityData = collatedAvailabilityData.filter(
		({ practitioner, reason }) => {
			let _matching = requestSlotTimeSelected.find(
				({ reasonId, startTime }) => reasonId === reason.id && startTime,
			);
			if (_matching) {
				return _matching.practitionerId === practitioner.id ? true : false;
			} else {
				return true;
			}
		},
	);

	const hasSlotSelected = (rsnId, ppId) =>
		availabilityRequests.find(
			({ reasonId, practitionerId, startTime }) =>
				reasonId === rsnId && practitionerId === ppId && startTime,
		);

	if (loading)
		return (
			<Row justify="center">
				<Spin />
			</Row>
		);

	const allAvailabilites = compact(
		flatten(collatedAvailabilityData.map((d) => d.availability)),
	).filter((d) => !isEmpty(d));

	if (isEmpty(allAvailabilites) && !loading)
		return (
			<Result
				icon={<CalendarOutlined />}
				subTitle="No availabilities for any staff. Please select another day."
			/>
		);

	return (
		<div>
			{collatedAvailabilityData?.map(
				({ practitioner, reason, availability, index }) => {
					matchingRequest = helperFindStartTime(
						availabilityRequests,
						reason.id,
						practitioner.id,
					);
					const slotSelected = hasSlotSelected(
						reason.id,

						practitioner.id,
					);

					if (isEmpty(flatten(availability))) {
						return null;
					}

					return (
						<div
							key={index}
							style={{
								paddingBottom: 16,
								paddingTop: 16,
								borderBottom: "1px solid #eee",
							}}
						>
							<Row style={{ marginBottom: 16 }} justify="space-between">
								<Col>
									<Space wrap>
										{practitioner.photo ? (
											<Avatar src={practitioner.photo}>
												{practitioner.firstName}
											</Avatar>
										) : (
											<Avatar
												style={{
													background: primaryDark,
												}}
											>
												<UserOutlined />
											</Avatar>
										)}
										{startCase(toLower(practitioner.fullNameWithTitle))}

										<Typography.Text strong>{reason.name} </Typography.Text>
										{reason.minutes > 0 && <Tag> {reason.minutes} Mins </Tag>}
									</Space>
									{slotSelected && (
										<Descriptions.Item>
											{" "}
											<Tag color="green">Selected</Tag>
										</Descriptions.Item>
									)}
								</Col>
								{isDesktop && (
									<Col>
										<Button
											icon={<DeleteOutlined />}
											danger
											size="small"
											type="text"
											onClick={() => {
												toggleItem({
													reasonId: reason.id,
													practitionerId: practitioner.id,
												});
												fetchProfessionalAvailabilityRequest(practiceId);
											}}
										/>
									</Col>
								)}
							</Row>
							{!slotSelected && (
								<>
									{availability && availability.length > 0 && (
										<Space size={0} wrap direction="vertical">
											<RenderSlot
												label="Morning"
												availability={availability?.[0]}
												updateItem={updateItem}
												practitioner={practitioner}
												reason={reason}
												matchingRequest={matchingRequest}
												practiceId={practiceId}
												reservationRequest={reservationRequest}
												deleteReservationRequest={deleteReservationRequest}
												date={date}
											/>
											<Spacer />
											<RenderSlot
												label="Afternoon"
												availability={availability?.[1]}
												updateItem={updateItem}
												practitioner={practitioner}
												reason={reason}
												matchingRequest={matchingRequest}
												practiceId={practiceId}
												reservationRequest={reservationRequest}
												deleteReservationRequest={deleteReservationRequest}
												date={date}
											/>
										</Space>
									)}
								</>
							)}
							<Spacer />
							{isMobile && (
								<Button
									icon={<DeleteOutlined />}
									danger
									size="small"
									block
									type="link"
									onClick={() => {
										toggleItem({
											reasonId: reason.id,
											practitionerId: practitioner.id,
										});
										fetchProfessionalAvailabilityRequest(practiceId);
									}}
								>
									Delete
								</Button>
							)}
						</div>
					);
				},
			)}
		</div>
	);
}

const mapStateToProps = (state) => ({
	collatedAvailabilityData: getAvailability(state.availability),
	availabilityRequests: getAvailabilityRequests(state.booking),
});
export default connect(mapStateToProps, { ...actions, ...bookingActions })(
	DisplayAvailableTime,
);
