import { combineReducers } from "redux";
import createReducer from "utils/createReducer";
import * as types from "./constants";

import { CLEAR_BOOKING } from "../Booking/constants";

const loginState = createReducer(false)({
  [types.LOGIN_REQUEST]: () => true,
  [types.LOGIN_SUCCESS]: () => false,
  [types.LOGIN_FAILURE]: () => false,
});

const signupState = createReducer(false)({
  [types.SIGNUP_REQUEST]: () => true,
  [types.SIGNUP_SUCCESS]: () => false,
  [types.SIGNUP_FAILURE]: () => false,
  [types.ADD_PATIENT_REQUEST]: () => true,
  [types.ADD_PATIENT_SUCCESS]: () => false,
  [types.ADD_PATIENT_FAILURE]: () => false,
});

const generateOtpState = createReducer(false)({
  [types.GENERATE_OTP_REQUEST]: () => true,
  [types.GENERATE_OTP_SUCCESS]: () => false,
  [types.GENERATE_OTP_FAILURE]: () => false,
});

const otpKey = createReducer(null)({
  [types.GENERATE_OTP_SUCCESS]: (state, { response }) => response.key,
  [types.CLEAR_OTP_KEY]: () => null,
  [types.CLEAR_PATIENTS]: () => null,
});

const otpMethod = createReducer(null)({
  [types.GENERATE_OTP_SUCCESS]: (state, { response }) => response.otpMethod,
  [types.CLEAR_OTP_KEY]: () => null,
  [types.CLEAR_PATIENTS]: () => null,
});

const otpEmail = createReducer(null)({
  [types.GENERATE_OTP_SUCCESS]: (state, { response }) => response.email,
  [types.CLEAR_OTP_KEY]: () => null,
  [types.CLEAR_PATIENTS]: () => null,
});

const otpMobilePhone = createReducer(null)({
  [types.GENERATE_OTP_SUCCESS]: (state, { response }) => response.mobilePhone,
  [types.CLEAR_OTP_KEY]: () => null,
  [types.CLEAR_PATIENTS]: () => null,
});

const otpMobileRegionCode = createReducer(null)({
  [types.GENERATE_OTP_SUCCESS]: (state, { response }) =>
    response.mobileRegionCode,
  [types.CLEAR_OTP_KEY]: () => null,
  [types.CLEAR_PATIENTS]: () => null,
});

const otpMobileRegionShort = createReducer(null)({
  [types.GENERATE_OTP_SUCCESS]: (state, { response }) =>
    response.mobileRegionShort,
  [types.CLEAR_OTP_KEY]: () => null,
  [types.CLEAR_PATIENTS]: () => null,
});

const otpVerified = createReducer(false)({
  [types.VERIFY_OTP_SUCCESS]: () => true,
  [CLEAR_BOOKING]: () => false,
  [types.CLEAR_PATIENTS]: () => false,
});

const otpVerificationRequestState = createReducer(false)({
  [types.VERIFY_OTP_REQUEST]: () => true,
  [types.VERIFY_OTP_SUCCESS]: () => false,
  [types.VERIFY_OTP_FAILURE]: () => false,
});

const formValues = createReducer({})({
  [types.VERIFY_OTP_SUCCESS]: (state, { response }) => response,
});

const patients = createReducer([])({
  [types.FETCH_PATIENTS_SUCCESS]: (state, { response }) => response,
  [types.CLEAR_PATIENTS]: () => [],
});

export const getLoginState = (state) => {
  if (state) {
    return state.loginState;
  }
};

export const getSignupState = (state) => {
  if (state) {
    return state.signupState;
  }
};

export const getOtpKey = (state) => {
  if (state) {
    return state.otpKey;
  }
};

export const getOtpEmail = (state) => {
  if (state) {
    return state.otpEmail;
  }
};

export const getOtpMethod = (state) => {
  if (state) {
    return state.otpMethod;
  }
};

export const getOtpMobilePhone = (state) => {
  if (state) {
    return state.otpMobilePhone;
  }
};

export const getOtpMobileRegionCode = (state) => {
  if (state) {
    return state.otpMobileRegionCode;
  }
};

export const getOtpMobileRegionShort = (state) => {
  if (state) {
    return state.otpMobileRegionShort;
  }
};

export const getGenerateOtpState = (state) => {
  if (state) {
    return state.generateOtpState;
  }
};

export const getOtpVerified = (state) => {
  if (state) {
    return state.otpVerified;
  }
};

export const getOtpVerificationRequestState = (state) => {
  if (state) {
    return state.otpVerificationRequestState;
  }
};

export const getPatients = (state) => {
  if (state) {
    return state.patients;
  }
};

export const getGuestUserFormValues = (state) => {
  if (state) {
    return state.formValues;
  }
};

export default combineReducers({
  loginState,
  signupState,
  generateOtpState,
  otpKey,
  otpVerified,
  otpVerificationRequestState,
  patients,
  formValues,
  otpMobilePhone,
  otpMobileRegionCode,
  otpMobileRegionShort,
  otpEmail,
  otpMethod,
});
