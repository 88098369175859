/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "./actions";
import { getAppointmentReasons } from "./reducers";
import RenderReasonList from "./RenderReasonList";
import * as bookingActions from "../Booking/actions";
import { getAvailabilityRequests } from "../Booking/reducers";

const ServiceList = ({
  fetchCatalogueItemsRequest,
  match,
  practiceId,
  reasons,
  toggleItem,
  selectItem,
  unselectItem,
  availabilityRequests,
}) => {
  useEffect(() => {
    if (practiceId) {
      fetchCatalogueItemsRequest(practiceId);
    }
  }, []);

  return (
    reasons && (
      <RenderReasonList
        reasons={reasons}
        selectItem={selectItem}
        unselectItem={unselectItem}
        toggleItem={toggleItem}
        availabilityRequests={availabilityRequests}
        multiSelect={false}
      />
    )
  );
};

const mapStateToProps = (state) => ({
  reasons: getAppointmentReasons(state.appointmentReasons),
  availabilityRequests: getAvailabilityRequests(state.booking),
});

export default connect(mapStateToProps, { ...actions, ...bookingActions })(
  ServiceList
);
