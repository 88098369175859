import { combineReducers } from "redux";
import createReducer from "utils/createReducer";
import {
  FETCH_APPOINTMENT_REASONS_SUCCESS,
  FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_SUCCESS,
} from "./constants";

const byIdSuccess = (state, { response }) =>
  response.entities.appointmentReasons || {};
const allIdsSuccess = (state, { response }) => response.result || [];

const byId = createReducer({})({
  [FETCH_APPOINTMENT_REASONS_SUCCESS]: byIdSuccess,
});

const allIds = createReducer([])({
  [FETCH_APPOINTMENT_REASONS_SUCCESS]: allIdsSuccess,
});

const practiceProfessionalAppointmentReasons = createReducer([])({
  [FETCH_PRACTICE_PROFESSIONAL_APPOINTMENT_REASONS_SUCCESS]: (
    state,
    { response }
  ) => response,
});

export const getAppointmentReason = (state, id) => state.byId[id];
export const getAppointmentReasons = (state) => {
  if (state) {
    return state.allIds.map((id) => state.byId[id]);
  }
  return [];
};

export const getPracticeProfessionalAppointmentReasons = (state) => {
  if (state) {
    return state.practiceProfessionalAppointmentReasons;
  }
};

export default combineReducers({
  byId,
  allIds,
  practiceProfessionalAppointmentReasons,
});
