import React from "react";
import { Select } from "antd";

const SelectField = ({
  input,
  children,
  mode,
  value,
  defaultValue,
  ...rest
}) => {
  if (!value && !defaultValue) {
    return <Select mode={mode} children={children} {...rest} />;
  } else {
    return (
      <Select
        mode={mode}
        defaultValue={defaultValue}
        value={value}
        children={children}
        {...rest}
      />
    );
  }
};

export default SelectField;
