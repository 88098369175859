import { all, call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as types from "./constants";

import api from "./api";

export function* fetchPractitionersSaga(action) {
  const { response, error } = yield call(api.fetchPractitioners);
  if (response) {
    yield put(actions.fetchPractitionersSuccess(response));
  } else {
    yield put(actions.fetchPractitionersFailure(error));
  }
}

export default function* root() {
  yield all([yield takeLatest(types.FETCH_PRACTITIONERS_REQUEST, fetchPractitionersSaga)]);
}
