import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";

import createRootReducer from "./reducers";

export const history = createBrowserHistory({ basename: `/` });

const composeEnhancers = composeWithDevTools({ name: "Zavy - Online Bookings" });

export default function configureStore(initialState) {
  const RouterMiddleware = routerMiddleware(history);
  const SagaMiddleware = createSagaMiddleware();
  return {
    ...createStore(
      createRootReducer(history),
      initialState,
      composeEnhancers(applyMiddleware(RouterMiddleware, SagaMiddleware), applyMiddleware(thunk))
    ),
    runSaga: SagaMiddleware.run,
  };
}
