import { all, fork } from "redux-saga/effects";

// Patient Sagas
import { LocationSaga } from "modules/Location";
import { AppointmentReasonSaga } from "modules/Service";
import { PractitionerSaga } from "modules/Practitioner";
import { BookingSaga } from "modules/Booking";
import { AvailabilitySaga } from "modules/Calendar";
import { SessionSaga } from "modules/Session";

export default function* RootSaga() {
  yield all([fork(LocationSaga)]);
  yield all([fork(AppointmentReasonSaga)]);
  yield all([fork(PractitionerSaga)]);
  yield all([fork(SessionSaga)]);
  yield all([fork(BookingSaga)]);
  yield all([fork(AvailabilitySaga)]);
}
