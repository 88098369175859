export const FETCH_PROFESSIONAL_AVAILABILITY_REQUEST = 'calendar/FETCH_PROFESSIONAL_AVAILABILITY_REQUEST';
export const FETCH_PROFESSIONAL_AVAILABILITY_SUCCESS = 'calendar/FETCH_PROFESSIONAL_AVAILABILITY_SUCCESS';
export const FETCH_PROFESSIONAL_AVAILABILITY_FAILURE = 'calendar/FETCH_PROFESSIONAL_AVAILABILITY_FAILURE';

export const RESERVATION_REQUEST = 'calendar/RESERVATION_REQUEST';
export const RESERVATION_SUCCESS = 'calendar/RESERVATION_SUCCESS';
export const RESERVATION_FAILURE = 'calendar/RESERVATION_FAILURE';

export const DELETE_RESERVATION_REQUEST = 'calendar/DELETE_RESERVATION_REQUEST';
export const DELETE_RESERVATION_SUCCESS = 'calendar/DELETE_RESERVATION_SUCCESS';
export const DELETE_RESERVATION_FAILURE = 'calendar/DELETE_RESERVATION_FAILURE';

export const FETCH_AVAILABILITY_BETWEEN_RANGE_REQUEST = 'calendar/FETCH_AVAILABILITY_BETWEEN_RANGE_REQUEST';
export const FETCH_AVAILABILITY_BETWEEN_RANGE_SUCCESS = 'calendar/FETCH_AVAILABILITY_BETWEEN_RANGE_SUCCESS';
export const FETCH_AVAILABILITY_BETWEEN_RANGE_FAILURE = 'calendar/FETCH_AVAILABILITY_BETWEEN_RANGE_FAILURE';


