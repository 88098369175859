import React from "react";
import { Tag, Typography } from "antd";
import { primaryDark } from "../../GlobalConfig";

function Slot({
  date,
  reservationRequest,
  deleteReservationRequest,
  practitioner,
  reason,
  startTime,
  doesMatching,
  updateItem,
  practiceId,
  reservationKey,
  anyBatch,
}) {
  let params = {};
  if (doesMatching) {
    params = {
      reasonId: reason.id,
      practitionerId: practitioner.id,
      date,
      reservationKey,
      anyBatch,
    };
  } else {
    params = {
      reasonId: reason.id,
      practitionerId: practitioner.id,
      date,
      startTime,
      reservationKey,
      anyBatch,
    };
  }

  return (
    <>
      <Typography.Link
        block
        style={{ cursor: "pointer" }}
        onClick={() =>
          doesMatching
            ? deleteReservationRequest(params, practiceId)
            : reservationRequest(params, practiceId)
        }
        type="primary"
        ghost={!doesMatching}
      >
        <Tag style={{ width: 80, textAlign: "center" }} color={primaryDark}>
          {startTime}
        </Tag>
      </Typography.Link>
    </>
  );
}
export default Slot;
