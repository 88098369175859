import * as constants from "./constants";

export const fetchLocationsRequest = (account) => ({
  type: constants.FETCH_LOCATIONS_REQUEST,
  account,
});

export const fetchLocationsSuccess = (response) => ({
  type: constants.FETCH_LOCATIONS_SUCCESS,
  response,
});

export const fetchLocationsFailure = (errors) => ({
  type: constants.FETCH_LOCATIONS_FAILURE,
  errors,
});

export const fetchCurrentLocationRequest = (locationId) => ({
  type: constants.FETCH_CURRENT_LOCATION_REQUEST,
  locationId,
});

export const fetchCurrentLocationSuccess = (response) => ({
  type: constants.FETCH_CURRENT_LOCATION_SUCCESS,
  response,
});

export const fetchCurrentLocationFailure = (errors) => ({
  type: constants.FETCH_CURRENT_LOCATION_FAILURE,
  errors,
});
