import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { Form, Button, Row, Col, Card } from "antd";
import {
  InputField,
  MaskInputField,
  InternationalPhoneField,
} from "components/AntdReduxForm";
import { required, length, date } from "redux-form-validators";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { getSignupState } from "modules/Session/reducers";
import { getOtpMethod } from "../reducers";

function NewPatientForm({ handleSubmit, signupState, dispatch, otpMethod }) {
  const [valid, setValid] = React.useState(false);

  const setRegionCode = (value) => {
    dispatch(change("NewPatientForm", "mobileRegionCode", value.code));
  };

  const setRegionShort = (value) => {
    dispatch(change("NewPatientForm", "mobileRegionShort", value.short));
  };

  const mobileRegionCode = useSelector(
    (state) =>
      state.locations.currentLocation.practiceLocalisationSetting
        .mobileRegionCode
  );

  const mobileRegionShort = useSelector(
    (state) =>
      state.locations.currentLocation.practiceLocalisationSetting
        .mobileRegionShort
  );

  const handleValidCheck = React.useCallback((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{8,10}$/;

    // We have to do the inverse here compared to in the Guest component
    // because the patient is entering the details of email if they verified by sms and vice versa
    if (otpMethod === "sms") {
      setValid(emailRegex.test(value));
    } else {
      setValid(phoneRegex.test(value.replace(/\s/g, '')));
    }
  }, [otpMethod])

  return (
    <Form layout="vertical">
      <Card title="Who are you booking for?">
        <form onSubmit={handleSubmit}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Field
                size="large"
                placeholder="Jane"
                name="firstName"
                validate={[required(), length({ max: 48 })]}
                label="First name"
                component={InputField}
                style={{ maxWidth: 350 }}
                required
              />
            </Col>
            <Col xs={24} md={12}>
              <Field
                size="large"
                placeholder="Doe"
                name="lastName"
                validate={[required(), length({ max: 48 })]}
                label="Last name"
                component={InputField}
                style={{ maxWidth: 350 }}
                required
              />
            </Col>
            <Col span={12}>
              <Field
                size="large"
                placeholder="dd/mm/yyyy"
                component={MaskInputField}
                validate={[required(), date({ format: "dd/mm/yyyy" })]}
                label="Date of Birth"
                mask={"99/99/9999"}
                style={{ maxWidth: 150 }}
                name="dob"
                required
              />
            </Col>
            {otpMethod === "sms" ? (
              <Col md={12} xs={24}>
                <Field
                  size="large"
                  placeholder="Email"
                  name="email"
                  validate={[required()]}
                  label="Email"
                  component={InputField}
                  style={{ maxWidth: 350 }}
                  onChange={(e) => handleValidCheck(e.target.value)}
                  required
                />
              </Col>
            ) : (
              <Col md={12} xs={24}>
                <Field
                  size="large"
                  name="mobilePhone"
                  validate={[required()]}
                  placeholder=""
                  label="Please enter your mobile number"
                  component={InternationalPhoneField}
                  autocomplete="off"
                  setRegionCode={setRegionCode}
                  setRegionShort={setRegionShort}
                  accountRegionShort={mobileRegionShort}
                  accountRegionCode={mobileRegionCode}
                  onChange={(e) => handleValidCheck(e)}
                  required
                  style={{ maxWidth: 550 }}
                />
              </Col>
            )}
          </Row>
          <Row className="mt-lg" justify="space-around">
            <Col>
              <Button
                size="large"
                type="primary"
                loading={signupState}
                disabled={!valid}
                htmlType="submit"
              >
                Save Details
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </Form>
  );
}

const mapStateToProps = (state) => ({
  signupState: getSignupState(state.session),
  otpMethod: getOtpMethod(state.session),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "NewPatientForm",
    enableReinitialize: true,
  })(NewPatientForm)
);
