import React from "react";
import { Field, reduxForm, change } from "redux-form";
import {
	Form,
	Button,
	Typography,
	Space,
	Divider,
	Tooltip,
	message,
} from "antd";
import { MessageOutlined } from "@ant-design/icons";
import {
	getSignupState,
	getOtpKey,
	getOtpMethod,
	getOtpMobilePhone,
	getOtpMobileRegionCode,
	getOtpMobileRegionShort,
	getOtpEmail,
} from "./reducers";
import { InputField, InternationalPhoneField } from "components/AntdReduxForm";
import { required } from "redux-form-validators";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "react-device-detect";
import { generateOtpRequest } from "./actions";

const { Text } = Typography;

function GuestForm({
	recaptcha,
	handleSubmit,
	signupState,
	otpKey,
	otpMethod,
	otpEmail,
	otpKeyMobilePhone,
	otpKeyMobileRegionCode,
	otpKeyMobileRegionShort,
	dispatch,
	handleResubmit,
	codeVerificationMethod,
	setCodeVerificationMethod,
	clearOtpKey,
}) {
	const [valid, setValid] = React.useState(false);
	const [canResend, setCanResend] = React.useState(false);

	const setRegionCode = (value) => {
		dispatch(change("GuestForm", "mobileRegionCode", value.code));
	};

	const setRegionShort = (value) => {
		dispatch(change("GuestForm", "mobileRegionShort", value.short));
	};

	const mobileRegionCode = useSelector(
		(state) =>
			state.locations.currentLocation.practiceLocalisationSetting
				.mobileRegionCode,
	);

	const mobileRegionShort = useSelector(
		(state) =>
			state.locations.currentLocation.practiceLocalisationSetting
				.mobileRegionShort,
	);

	const handleValidCheck = React.useCallback(
		(value) => {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			const phoneRegex = /^\d{8,10}$/;

			if (codeVerificationMethod === "sms") {
				setValid(phoneRegex.test(value.replace(/\s/g, "")));
			} else {
				setValid(emailRegex.test(value));
			}
		},
		[codeVerificationMethod],
	);

	const recaptchaHandleResubmit = (values) => {
		const captchaValue = recaptcha.current.getValue();
		if (!captchaValue) {
			message.error("Please verify the reCAPTCHA!");
		} else {
			setCanResend(false);
			// make form submission
			setTimeout(() => setCanResend(true), 30000);
			handleResubmit(values, captchaValue);
		}
	};

	const recaptchaHandleSubmit = (values) => {
		const captchaValue = recaptcha.current.getValue();
		dispatch(change("GuestForm", "recaptcha", captchaValue));

		if (!captchaValue) {
			message.error("Please verify the reCAPTCHA!");
		} else {
			// make form submission
			setTimeout(() => setCanResend(true), 30000);
			handleSubmit();
		}
	};

	const resendAddress = React.useMemo(
		() =>
			({
				sms: `+${otpKeyMobileRegionCode}${Number(
					otpKeyMobilePhone?.replace(/\s/g, ""),
				)}`,
				email: otpEmail,
			})[otpMethod],
		[otpEmail, otpKeyMobilePhone, otpKeyMobileRegionCode, otpMethod],
	);

	return (
		<>
			<Form layout="vertical">
				<div
					bordered={false}
					style={{
						maxWidth: "500px",
						marginLeft: "auto",
						marginRight: "auto",
					}}
				>
					<Typography.Title level={isMobile ? 5 : 3}>
						We are just checking you're human
					</Typography.Title>

					<div className="pb-lg">
						{/* <Text type="secondary">{resendAddress}</Text> */}
						{otpKey ? (
							<div>
								<Field
									size="large"
									name="otp"
									placeholder="Enter Code"
									validate={[required()]}
									prefix={<MessageOutlined />}
									label={<>We have sent a code to {resendAddress} </>}
									component={InputField}
									autocomplete="off"
									style={{ maxWidth: 550 }}
									required
								/>
								<Button
									block={isMobile ? "true" : "false"}
									type="primary"
									loading={signupState}
									htmlType="submit"
									onClick={(values) => recaptchaHandleSubmit(values)}
								>
									Verify
								</Button>
								<>
									{codeVerificationMethod === "sms" ? (
										<>
											<Divider dashed />

											<Space>
												<Text type="secondary">Didn't receive your code?</Text>

												<Tooltip
													title={
														canResend
															? resendAddress
															: "Can only resend every 30 seconds"
													}
												>
													<Button
														disabled={!canResend}
														block={isMobile ? "true" : "false"}
														type="default"
														loading={signupState}
														htmlType="button"
														onClick={() =>
															recaptchaHandleResubmit({
																mobilePhone: otpKeyMobilePhone,
																mobileRegionCode: otpKeyMobileRegionCode,
																mobileRegionShort: otpKeyMobileRegionShort,
																otpMethod: otpMethod,
																recaptcha: recaptcha.current.getValue(),
															})
														}
													>
														Resend
													</Button>
												</Tooltip>
												<Button
													block={isMobile ? "true" : "false"}
													type="default"
													onClick={() => {
														clearOtpKey();
														setCodeVerificationMethod("email");
														setValid(false);
														dispatch(change("GuestForm", "mobilePhone", ""));
													}}
												>
													Email Verification
												</Button>
											</Space>
										</>
									) : (
										<>
											<Divider dashed />
											<Space wrap>
												<Text type="secondary">Didn't receive your code?</Text>

												<Tooltip
													title={
														canResend
															? resendAddress
															: "Can only resend every 30 seconds"
													}
												>
													<Button
														disabled={!canResend}
														type="default"
														loading={signupState}
														htmlType="button"
														onClick={() =>
															recaptchaHandleResubmit({
																email: otpEmail,
																otpMethod: otpMethod,
																recaptcha: recaptcha.current.getValue(),
															})
														}
													>
														Resend
													</Button>
												</Tooltip>

												<Button
													type="default"
													onClick={() => {
														clearOtpKey();
														setCodeVerificationMethod("sms");
														setValid(false);
														dispatch(change("GuestForm", "email", ""));
													}}
												>
													SMS Verification
												</Button>
											</Space>
										</>
									)}
								</>
							</div>
						) : (
							<>
								{codeVerificationMethod === "sms" ? (
									<>
										<Field
											size="large"
											name="mobilePhone"
											validate={[required()]}
											placeholder=""
											label="Please enter your mobile number"
											component={InternationalPhoneField}
											autocomplete="off"
											setRegionCode={setRegionCode}
											setRegionShort={setRegionShort}
											accountRegionShort={mobileRegionShort}
											accountRegionCode={mobileRegionCode}
											onChange={(e) => handleValidCheck(e)}
											required
											style={{ maxWidth: 550 }}
										/>
										<Space wrap>
											<Button
												type="default"
												onClick={() => {
													clearOtpKey();
													setCodeVerificationMethod("email");
													setValid(false);
													dispatch(change("GuestForm", "mobilePhone", ""));
												}}
											>
												Email Verification
											</Button>{" "}
											<Button
												type="primary"
												loading={signupState}
												// htmlType="submit"
												// onClick={handleSubmit}
												onClick={(values) => {
													recaptchaHandleSubmit(values);
												}}
												disabled={!valid}
											>
												Send Code
											</Button>
										</Space>
									</>
								) : (
									<>
										<Field
											size="large"
											name="email"
											validate={[required()]}
											placeholder="example@example.com"
											label="Please enter your email address"
											component={InputField}
											autocomplete="off"
											required
											onChange={(e) => handleValidCheck(e.target.value)}
											style={{ maxWidth: 550 }}
										/>
										<Space>
											<Button
												type="default"
												onClick={() => {
													clearOtpKey();
													setCodeVerificationMethod("sms");
													setValid(false);
													dispatch(change("GuestForm", "email", ""));
												}}
											>
												SMS Verification
											</Button>
											<Button
												type="primary"
												loading={signupState}
												// htmlType="submit"
												// onClick={handleSubmit}
												onClick={() => {
													recaptchaHandleSubmit();
												}}
												disabled={!valid}
											>
												Send Code
											</Button>
										</Space>
									</>
								)}
							</>
						)}
					</div>
				</div>
				<div className="mt-lg" />
			</Form>
		</>
	);
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ change }, dispatch);

const mapStateToProps = (state) => ({
	signupState: getSignupState(state.session),
	otpKey: getOtpKey(state.session),
	otpMethod: getOtpMethod(state.session),
	otpEmail: getOtpEmail(state.session),
	otpKeyMobilePhone: getOtpMobilePhone(state.session),
	otpKeyMobileRegionCode: getOtpMobileRegionCode(state.session),
	otpKeyMobileRegionShort: getOtpMobileRegionShort(state.session),
	generateOtpRequest,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(
	reduxForm({
		form: "GuestForm",
	})(GuestForm),
);
