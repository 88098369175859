import { combineReducers } from "redux";
import moment from "moment";
import createReducer from "utils/createReducer";
import * as types from "./constants";
import { ADD_PATIENT_SUCCESS } from "modules/Session/constants";

let today = moment().format("DD/MM/YYYY");

const helperFindIndx = (state, item) =>
	state.findIndex((currentItem, index) => {
		let isPractitioner = currentItem.practitionerId === item.practitionerId;
		let isReason = currentItem.reasonId === item.reasonId;
		let time = currentItem.time === item.time;
		return isPractitioner && isReason && time;
	});

const fromDate = createReducer(today)({
	[types.CHANGE_FROM_DATE]: (state, { newDate }) => newDate,
	[types.CLEAR_BOOKING]: () => today,
	[types.SAVE_BOOKING_SUCCESS]: () => today,
});

const untilDate = createReducer(today)({
	[types.CLEAR_BOOKING]: () => today,
	[types.SAVE_BOOKING_SUCCESS]: () => today,
});

const availabilityRequests = createReducer([])({
	[types.UPDATE_AVAILABILITY_REQUESTS]: (state, { requests }) => requests,
	[types.CLEAR_BOOKING]: () => [],
	// [types.SAVE_BOOKING_SUCCESS]: () => [],
	[types.UPDATE_ITEM]: (state, { item }) => {
		let index = helperFindIndx(state, item);
		if (index > -1) {
			let newArray = [...state];
			newArray[index] = item;
			return [...newArray];
		} else {
			return [...state];
		}
	},
	[types.DELETE_REQUEST_UNLESS_VALID_SLOT]: (state) => [
		...state.filter(({ startTime }) => startTime),
	],
	[types.TOGGLE_ITEM]: (state, { item }) => {
		let index = helperFindIndx(state, item);
		let newArray = [];
		if (index > -1) {
			// Check the index has anyBatch id
			let record = state[index];
			if (record.anyBatch) {
				// get rid of all match anyBatch
				newArray = [
					...state.filter(({ anyBatch }) => anyBatch !== record.anyBatch),
				];
			} else {
				// or just get rid of that single record
				newArray = [...state];
				newArray.splice(index, 1);
			}
			return [...newArray];
		} else {
			return [...state, item];
		}
	},
	[types.CHANGE_FROM_DATE]: (state, action) => [
		...state.map((s) => ({ ...s, startTime: null })),
	],
});

const patientNote = createReducer(null)({
	[types.CHANGE_PATIENT_NOTE]: (state, { note }) => note,
	[types.SAVE_BOOKING_SUCCESS]: () => null,
});

const reviewAppointments = createReducer([])({
	[types.REVIEW_BOOKING_SUCCESS]: (state, { response }) => response,
	[types.SAVE_BOOKING_SUCCESS]: () => [],
});

const confirmedAppointments = createReducer([])({
	[types.SAVE_BOOKING_SUCCESS]: (state, { response }) => response,
});

const saveBookingState = createReducer(false)({
	[types.SAVE_BOOKING_REQUEST]: () => true,
	[types.SAVE_BOOKING_SUCCESS]: () => false,
	[types.SAVE_BOOKING_FAILURE]: () => false,
	[types.MAKE_PAYMENT_REQUEST]: () => true,
	[types.MAKE_PAYMENT_SUCCESS]: () => false,
	[types.MAKE_PAYMENT_FAILURE]: () => false,
	[types.START_BOOKING_PROCESS]: () => true,
});

export const getAvailabilityRequests = (state) => {
	if (state && state.availabilityRequests) {
		return state.availabilityRequests;
	}

	if (state && state.booking) {
		return state.booking.availabilityRequests;
	}
};
export const hasTimeSelectedForRequests = (state) => {
	let requests = getAvailabilityRequests(state);

	let noOfRequests = getNoOfRequests(requests);

	//let reasonIds = requests.map((i) => i.reasonId);
	// let sameServiceInAllRequests = reasonIds.every((val, i, arr) => val === arr[0]);
	let slotSelectedForRequests = requests
		.map((a) => a.startTime)
		.filter((a) => a);

	return slotSelectedForRequests.length === noOfRequests;
	// return sameServiceInAllRequests ? slotSelectedForRequests > 0 : slotSelectedForRequests.length
};

const currentPatient = createReducer(null)({
	[types.SET_CURRENT_PATIENT]: (state, { patient }) => patient,
	[types.CLEAR_CURRENT_PATIENT]: () => null,
	[ADD_PATIENT_SUCCESS]: (state, { response }) => response && response.id,
	[types.GET_PAYMENT_INTENT_SUCCESS]: (state, { response }) =>
		Object.assign({}, state, { customerIntent: response }),
});

const paymentDrawerState = createReducer(false)({
	[types.OPEN_PAYMENT_DRAWER]: () => true,
	[types.CLOSE_PAYMENT_DRAWER]: () => false,
	// [types.CREATE_PAYMENT_SUCCESS]: () => false,
});

export const getFromDate = (state) => {
	if (state?.fromDate) {
		return state.fromDate;
	}
	if (state?.booking) {
		return state.booking.fromDate;
	}
};

export const getUntilDate = (state) => {
	if (state) {
		return state.untilDate;
	}
};

export const getReviewAppointments = (state) => {
	if (state) {
		return state.reviewAppointments;
	}
};

export const getConfirmedAppointments = (state) => {
	if (state) {
		return state.confirmedAppointments;
	}
};

export const getSaveBookingState = (state) => {
	if (state) {
		return state.saveBookingState;
	}
};

export const getCurrentPatient = (state) => {
	if (state?.currentPatient) {
		return state.currentPatient;
	}
	if (state?.booking) {
		return state.booking.currentPatient;
	}
};

export const getCurrentLocation = (state) => {
	if (state?.locations) {
		return state.locations.currentLocation;
	}
};

export const getNoOfRequests = (availabilityRequests) => {
	const notAnyRequests = availabilityRequests.filter(
		({ anyBatch }) => !anyBatch,
	);

	return notAnyRequests.length + getNoOfAnyRequests(availabilityRequests);
};

export const getNoOfAnyRequests = (availabilityRequests) => {
	const anyRequests = availabilityRequests
		.filter(({ anyBatch }) => anyBatch)
		.map(({ anyBatch }) => anyBatch);
	return [...new Set(anyRequests)].length;
};

export default combineReducers({
	fromDate,
	untilDate,
	availabilityRequests,
	patientNote,
	reviewAppointments,
	confirmedAppointments,
	saveBookingState,
	currentPatient,
	paymentDrawerState,
});
