export const apiUrl =
  (account = "public") =>
  (route) => {
    let endPoint = `${process.env.REACT_APP_ZAVY_PRACTICE_URL}${route}.json`;
    let re = /public/gi;

    if (account !== "public") {
      return endPoint.replace(re, account);
    } else {
      return endPoint;
    }
  };

export const sessionApiUrl = () => (route) => {
  return process.env.REACT_APP_ZAVY_PRACTICE_URL.replace(
    "online_booking",
    `${route}`
  );
};

export const emailRegex =
  "(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])";

export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
