import React from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as actions from "./actions";
import { getCurrentPatient } from "modules/Booking/reducers";
// import Login from "./Login"
// import Confirm from "modules/Booking/Confirm"

function App({ loginRequest, match, currentPatient }) {
  // const { params } = match;
  // const practiceId = params.locationId;

  // let requestsWithNoTime = availabilityRequests.filter((r) => !r.startTime)
  // requestsWithNoTime.map((request) => toggleItem(request))

  return (
    <>
      {!currentPatient && <Redirect to="login" />}
      {currentPatient && <Redirect to="confirm" />}
    </>
  );
}

const mapStateToProps = (state) => ({
  currentPatient: getCurrentPatient(state.booking),
});

export default connect(mapStateToProps, actions)(App);
