/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { green } from "@ant-design/colors";
import { Table, Space, Button, Row, Col, Typography, Card, Tooltip } from "antd";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPatients } from "modules/Session/reducers";
import { getCurrentLocation } from "modules/Location/reducers";
import * as actions from "modules/Session/actions";

const { Text } = Typography;

function ExistingPatients({
  fetchPatientsRequest,
  patients,
  location,
  account,
  practiceId,
  setCurrentPatient,
  currentPatient,
  clearPatients,
}) {
  const history = useHistory();

  useEffect(() => {
    fetchPatientsRequest(account, practiceId);
  }, []);

  const handleSetPatient = (record) => {
    setCurrentPatient(record);
    history.push("confirm");
  };

  const currentPractice = useSelector(
    (state) => state.locations.currentLocation
  );

  const pendingPatientAllowed = useMemo(() => currentPractice?.allowPendingPatients, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "fullNameWithTitle",
      key: "fullNameWithTitle",
    },
    {
      title: "Select ",
      fixed: "right",
      key: "action",
      render: (text, record) => {
        return pendingPatientAllowed ? (
          <Space size="middle">
            <Button
              type="primary"
              ghost
              icon={currentPatient?.id === record.id && <CheckCircleOutlined style={{ color: green[4] }} />}
              onClick={() => handleSetPatient(record)}
            >
              {currentPatient?.id !== record.id && "Select"}
            </Button>
          </Space>
        ) : (
          <Space size="middle">
            <Tooltip
              title={record?.state !== 'active' && `We found a match but it seems the practice has marked you as inactive. ${
                location?.phone
                  ? `Please call them on ${location?.phone} to set you as active.`
                  : "Please contact them to set you as active."
              }`}
            >
              <Button
                icon={currentPatient?.id === record.id && <CheckCircleOutlined style={{ color: green[4] }} />}
                disabled={record?.state !== 'active'}
                type="primary"
                ghost
                onClick={() => handleSetPatient(record)}
              >
                {currentPatient?.id !== record.id && "Select"}
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <Card size="small" title="Who are you booking for?">
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Text type="secondary">
            We detected multiple linked accounts, please select who this appointment is for. Alternatively if you need
            to create another account please contact us. <br />
          </Text>
        </Col>
        <Col span={24}>
          <Table size="small" dataSource={patients} columns={columns} />
        </Col>
      </Row>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  patients: getPatients(state.session),
  location: getCurrentLocation(state.locations),
});

export default connect(mapStateToProps, actions)(ExistingPatients);
