/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Row, Col, Button, Collapse } from "antd";
import { BrowserView, MobileView } from "react-device-detect";

import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./actions";
import RenderTime from "./RenderTime";

import { accountBaseUrl } from "../../helpers/account";
import { changeFromDate, clearCurrentPatient } from "../Booking/actions";
import { clearPatients } from "../Session/actions";

import DatePicker from "./DatePicker";
import {
	getAvailabilityLoadingState,
	getAvailabilityRangeLoadingState,
} from "./reducers";
import {
	getFromDate,
	hasTimeSelectedForRequests,
	getAvailabilityRequests,
} from "../Booking/reducers";
import { fetchCurrentLocationRequest } from "../Location/actions";
import { getCurrentLocation } from "../Location/reducers";
import { fetchPracticeProfessionalAppointmentReasonsRequest } from "../Service/actions";

import ContentContainer from "../../components/ContentContainer";
import { ArrowLeftOutlined } from "@ant-design/icons";
// import { Footer } from "antd/lib/layout/layout";

const hGutter = {
	xs: 16,
	md: 32,
	lg: 32,
	xl: 32,
};

const Availability = ({
	fromDate,
	availabilityLoadingState,
	fetchProfessionalAvailabilityRequest,
	match,
	changeFromDate,
	isTimeSlotSelected,
	// availabilityRequests,
	fetchCurrentLocationRequest,
	fetchPracticeProfessionalAppointmentReasonsRequest,
	fetchAvailabilityBetweenRangeRequest,
	currentPractice,
	clearCurrentPatient,
	clearPatients,
	availabilityRangeLoadingState,
}) => {
	const { params } = match;
	const practiceId = params.locationId;
	// const [visible, setVisible] = useState(false);

	useEffect(() => {
		clearCurrentPatient();
		clearPatients();

		const { reasonId, practitionerId } = params;
		if (reasonId || practitionerId) {
			fetchPracticeProfessionalAppointmentReasonsRequest(
				practiceId,
				reasonId,
				practitionerId,
			);
		} else {
			fetchProfessionalAvailabilityRequest(practiceId);
		}
		fetchCurrentLocationRequest(practiceId);
	}, []);

	const handleDateChange = (id, date) => {
		changeFromDate(id, date);
		// setVisible(true);
	};

	return (
		<>
			<ContentContainer
				showNext={false}
				step={3}
				stepPercentage={60}
				pageTitle="Select suitable date and time"
				extra={
					isTimeSlotSelected && (
						<Redirect
							to={`${accountBaseUrl}/locations/${practiceId}/session`}
						/>
					)
				}
				backButton={
					<Link to={`${accountBaseUrl}/locations/${practiceId}/reasons`}>
						<Button shape="round" type="text" icon={<ArrowLeftOutlined />} />
					</Link>
				}
			>
				<BrowserView>
					<Row gutter={hGutter}>
						<Col flex="350px">
							<DatePicker
								fromDate={fromDate}
								{...currentPractice}
								changeFromDate={changeFromDate}
								practiceId={practiceId}
								fetchAvailabilityBetweenRangeRequest={
									fetchAvailabilityBetweenRangeRequest
								}
								availabilityRangeLoadingState={availabilityRangeLoadingState}
							/>
						</Col>

						<Col flex="1">
							<RenderTime practiceId={practiceId} date={fromDate} />
						</Col>
					</Row>
				</BrowserView>

				<MobileView>
					<Collapse size="small" defaultActiveKey={["Date", "Time"]}>
						<Collapse.Panel header="Pick date" key="Date">
							<DatePicker
								{...currentPractice}
								changeFromDate={handleDateChange}
								practiceId={practiceId}
								fetchAvailabilityBetweenRangeRequest={
									fetchAvailabilityBetweenRangeRequest
								}
								availabilityRangeLoadingState={availabilityRangeLoadingState}
							/>
						</Collapse.Panel>
						<Collapse.Panel header="Select time" key="Time">
							<RenderTime practiceId={practiceId} date={fromDate} />
						</Collapse.Panel>
					</Collapse>
				</MobileView>
			</ContentContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	availabilityLoadingState: getAvailabilityLoadingState(state.availability),
	availabilityRangeLoadingState: getAvailabilityRangeLoadingState(
		state.availability,
	),
	isTimeSlotSelected: hasTimeSelectedForRequests(state.booking),
	fromDate: getFromDate(state.booking),
	availabilityRequests: getAvailabilityRequests(state.booking),
	currentPractice: getCurrentLocation(state.locations),
});

export default connect(mapStateToProps, {
	...actions,
	changeFromDate,
	fetchCurrentLocationRequest,
	fetchPracticeProfessionalAppointmentReasonsRequest,
	clearCurrentPatient,
	clearPatients,
})(Availability);
