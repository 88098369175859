/* eslint-disable import/no-anonymous-default-export */
import { account } from "helpers/account";
import {
  errorResponse,
  makePOSTRequest,
  handleResponse,
  makeDELETERequest,
} from "utils/apiRequests";
import { apiUrl } from "../../config";

const fetchProfessionalAvailability = ({
  practiceId,
  availabilityRequests,
  fromDate,
}) => {
  const url = apiUrl(account)(`/practices/${practiceId}/vacancies`);
  return makePOSTRequest(url)({
    onlineBookingVacancyForm: { practiceId, availabilityRequests, fromDate },
  })
    .then(handleResponse)
    .catch(errorResponse);
};

const fetchAvailabilityBetweenRange = ({
  practiceId,
  availabilityRequests,
  fromDate,
  toDate,
}) => {
  const url = apiUrl(account)(
    `/practices/${practiceId}/vacancies/search_availability`
  );
  return makePOSTRequest(url)({
    onlineBookingVacancyForm: { practiceId, availabilityRequests, fromDate, toDate },
  })
    .then(handleResponse)
    .catch(errorResponse);
};

const reservation = ({ practiceId, requestItem }) => {
  const url = apiUrl(account)(
    `/practices/${practiceId}/appointment_reservations`
  );
  return makePOSTRequest(url)({
    onlineBookingReservationForm: { ...requestItem },
  })
    .then(handleResponse)
    .catch(errorResponse);
};

const deleteReservation = ({ practiceId, requestItem }) => {
  const { reservationKey } = requestItem;
  if (!reservation) return;
  const url = apiUrl(account)(
    `/practices/${practiceId}/appointment_reservations/${reservationKey}`
  );
  return makeDELETERequest(url).then(handleResponse).catch(errorResponse);
};

export default {
  fetchProfessionalAvailability,
  fetchAvailabilityBetweenRange,
  reservation,
  deleteReservation,
};
