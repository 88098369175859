/* eslint-disable import/no-anonymous-default-export */
import { account } from "helpers/account";
import {
  normalizeResponse,
  errorResponse,
  makeGETRequest,
  handleResponse,
} from "utils/apiRequests";
import { appointmentReasonsListSchema } from "./schema";
import { apiUrl } from "../../config";

const fetchAppointmentReasons = ({ practiceId }) => {
  const url = apiUrl(account)(`/practices/${practiceId}/appointment_reasons`);
  const covertNormalizeRespone = (response) =>
    normalizeResponse(response, appointmentReasonsListSchema);
  return makeGETRequest(url).then(covertNormalizeRespone).catch(errorResponse);
};

const fetchPracticeProfessionalAppointmentReasons = ({
  practiceId,
  reasonId,
  practitionerId,
}) => {
  let appendReason = reasonId ? `by_appointment_reason=${reasonId}` : "";
  let appendPractitioner = practitionerId ? `&by_id=${practitionerId}` : "";
  const url = apiUrl(account)(
    `/practices/${practiceId}/practice_professionals`
  );
  return makeGETRequest(`${url}?${appendReason}${appendPractitioner}`)
    .then(handleResponse)
    .catch(errorResponse);
};

export default {
  fetchAppointmentReasons,
  fetchPracticeProfessionalAppointmentReasons,
};
